import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/pilotage/invoices', config);
}

const get = async (id) => {
    return FinanceApi.get('/pilotage/invoices/' + id);
}

const getByCustomerId = async (id) => {
    return FinanceApi.get('/pilotage/invoices/customer/' + id);
}

const store = async (data) => {
    return FinanceApi.post('/pilotage/invoices', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/pilotage/invoices/' + id, formData);
}

const del = async (id) => {
    return FinanceApi.delete('/pilotage/invoices/' + id);
}

export default {
    getAll,
    get,
    getByCustomerId,
    store,
    update,
    del
}
