<template>
    <div ref="container">
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <div v-if="data != null && data.customer != null">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('student_number') }}:</label>{{ data.customer.number }}
                                </div>
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('national_number') }}:</label>{{ data.customer.national_id }}
                                </div>
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('name_surname')
                                }}:</label>{{ data.customer.name }}
                                    {{ data.customer.surname }}
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">
                                        {{ $t('student_group') }}:
                                    </label>
                                    <div v-if="checkPermission('customer_patch')">
                                        <multi-selectbox :multiple="false" :allow-empty="false"
                                            :options="[{ value: 'TR', text: 'TR' }, { value: 'YU', text: 'YU' }]"
                                            v-on:optionSelected="customerGroupChanged"
                                            v-model="data.customer.group"></multi-selectbox>
                                    </div>
                                    <div v-else>
                                        {{ data.customer.group }}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <b-row>
                                        <b-col sm="auto">
                                            <b-badge
                                                :variant="(data.customer.payment_status == 'E' ? 'success' : 'danger') + ' text-uppercase'">
                                                <span class="d-flex align-items-center">
                                                    <i
                                                        :class="'ri-' + (data.customer.payment_status == 'E' ? 'check' : 'close') + '-line mr-1 top-minus-1 font-size-base'"></i>
                                                    {{ this.$t('payment_status_' + data.customer.payment_status) }}
                                                </span>
                                            </b-badge>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-button variant="link p-0" size="xs" @click="checkApproval"
                                                v-show="!checkPayment">
                                                <div class="d-flex align-items-center">
                                                    <b-spinner label="Spinning" class="mr-3" small
                                                        v-show="checkSpinner"></b-spinner>
                                                    {{ $t('check_payment_approval').toUpper() }}
                                                </div>
                                            </b-button>
                                            <b-button variant="link p-0" size="xs" @click="checkApproval"
                                                v-show="checkPayment">
                                                <span v-show="checkPayment">
                                                    <span v-show="paymentControl" class="text-success"><span
                                                            class="d-flex align-items-center"><i
                                                                class="ri-check-line mr-2 ri-lg"
                                                                v-show="!checkSpinner"></i><b-spinner label="Spinning"
                                                                class="mr-3 text-primary" small
                                                                v-show="checkSpinner"></b-spinner>{{
                                                                    $t('payment_approval_successful') }}</span></span>
                                                    <span v-show="!paymentControl" class="text-danger"><span
                                                            class="d-flex align-items-center"><i
                                                                class="ri-close-line mr-2 ri-lg"
                                                                v-show="!checkSpinner"></i><b-spinner label="Spinning"
                                                                class="mr-3 text-primary" small
                                                                v-show="checkSpinner"></b-spinner>{{
                                                                    $t('payment_approval_failed')
                                                                }}</span></span>
                                                </span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('faculty')
                                }}:</label>{{ getLocaleText(data.customer, 'faculty_name') }}
                                </div>
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('program')
                                }}:</label>{{ getLocaleText(data.customer, 'program_name') }}
                                    ({{ data.customer.program_code }})
                                </div>
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('class')
                                }}:</label>{{ data.customer.class_name }}
                                </div>
                                <div class="col-12 mb-3" v-if="data.customer && data.customer.student_status">
                                    <label class="col-form-label mr-1 p-0">{{ $t('student_status') }}:</label>{{
                                        getLocaleText(data.customer.student_status, 'name') }}
                                    <span v-if="data.customer && data.customer.semester_status"> / {{
                                        getLocaleText(data.customer.semester_status, 'name') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5 d-flex" v-if="data != null && data.customer != null">
                <div class="row">
                    <div class="col-auto" v-if="checkPermission('scholarship_store')">
                        <b-button variant="outline-primary" @click="newScholarship"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('add_scholarship').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto">
                        <b-button variant="outline-primary" @click="addPaymentPlan"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('add_payment_plan').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto"
                        v-if="student_program_id && student_program_id > 0 && data && data.customer && ['L', 'OL'].includes(data.customer.program_level)">
                        <b-button variant="outline-primary" @click="$bvModal.show('creditPriceModal')">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('credit_price').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.customer && ['YL', 'D'].includes(data.customer.program_level)">
                        <b-button variant="outline-primary" @click="$bvModal.show('coursePriceModal')">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('course_price').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.customer && ['D'].includes(data.customer.program_level)">
                        <b-button variant="outline-primary" @click="$bvModal.show('scientificPreparationModal')">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('scientific_preparation').toUpper()
                            }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto">
                        <b-button variant="outline-primary" @click="findStudent"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('find_student').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.offsets.length > 0">
                        <b-button :variant="data.offset_balance>0?'outline-danger':'outline-primary'" @click="offsetDetail"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('offsets_detail').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="checkPermission('paymentapprovalexception_store')
                        && data
                        && (!data.payment_approval_exception || data.payment_approval_exception.status == 'H')">
                        <b-button variant="outline-success" @click="givePaymentApprovalException"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('give_exception_payment_approval').toUpper()
                                    }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="checkPermission('paymentapprovalexception_store')
                        && data
                        && (data.payment_approval_exception && data.payment_approval_exception.status == 'E')">
                        <b-button variant="outline-danger" @click="removePaymentApprovalException"><span class="d-flex"><i
                                    class="ri-add-line mr-2"></i>{{ $t('remove_exception_payment_approval').toUpper()
                                    }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.customer.program_code == '42022101'">
                        <b-button variant="outline-primary" @click="$bvModal.show('pilotageFeeModal')">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                $t('create_pilotage_fee').toUpper()
                            }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.customer.program_code == '42022101'">
                        <b-button variant="outline-primary" @click="pilotageInvoices">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                $t('pilotage_invoices').toUpper()
                            }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="student_program_id && student_program_id > 0">
                        <b-button variant="outline-primary" @click="showOtherPayments()">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('other_payments').toUpper() }}</span>
                        </b-button>
                    </div>
                    <div class="col-auto" v-if="data && data.customer && ['YL', 'D'].includes(data.customer.program_level)">
                        <b-button variant="outline-primary" @click="$bvModal.show('graduatePaymentPlan')">
                            <span class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                $t('yl_payment_plan').toUpper()
                            }}</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <b-tabs content-class="py-5 position-relative"
                v-if="data != null && data.periods != null && data.periods.length > 0">
                <div class="tabs-bottom-line"></div>
                <b-tab v-for="period in data.periods" v-bind:key="period" :active="period === data.active_period">
                    <template #title><span class="d-flex align-items-center"><i
                                class="ri-calendar-line top-plus-1 mr-2"></i>{{
                                    (data.period_alias && data.period_alias[period]) ? data.period_alias[period] : period
                                }}</span></template>

                    <div class="row" v-if="data.scholarships != null && data.scholarships[period] != null">
                        <div class="col-auto mb-3" v-if="data.discounts[period]">
                            <div class="badge badge-info">
                                <b class="font-size-base mr-1">{{ $t('total_discount_rate') }}:</b><span
                                    class="badge badge-success">%{{
                                        parseFloat(
                                            data.discounts[period].discount_rate)
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-auto mb-3"
                            v-if="data.discounts[period] && parseFloat(data.discounts[period].discount_amount) > 0">
                            <div class="badge badge-info">
                                <b class="font-size-base mr-1">{{ $t('total_discount_amount') }}:</b><span
                                    class="badge badge-success">{{
                                        parseFloat(
                                            data.discounts[period].discount_amount)
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-auto mb-3">
                            <b-button variant="outline-primary" @click="$bvModal.show('scholarshipDetail' + period)">
                                {{ toUpperCase('scholarship_detail') }}
                            </b-button>
                        </div>
                    </div>

                    <CommonModal :id="'scholarshipDetail' + period" size="lg">
                        <template v-slot:CommonModalTitle>
                            <div>{{ $t('scholarships').toUpper() }}</div>
                        </template>
                        <template v-slot:CommonModalContent>
                            <b-table v-if="data.scholarships != null && data.scholarships[period] != null" bordered striped
                                responsive :items="data.scholarships[period]" :fields="scholarshipFields"
                                class="mb-5 table-dropdown no-scrollbar border rounded">
                                <template #cell(dropdownTable)="row">
                                    <div class="d-flex">
                                        <b-button class="mr-3" variant="outline-primary" size="xs" v-b-modal.CommonModal
                                            @click="editScholarship(row.item)">{{ $t('edit') }}
                                        </b-button>
                                        <b-button v-if="row.item.status == 'pending'" class="mr-3" variant="outline-success"
                                            size="xs" v-b-modal.CommonModal
                                            @click="approveScholarship(row.item, 'approved')">{{ $t('approve') }}
                                        </b-button>
                                        <b-button
                                            v-if="row.item.status == 'approved' && checkPermission('scholarship_approve')"
                                            class="mr-3" variant="outline-danger" size="xs" v-b-modal.CommonModal
                                            @click="approveScholarship(row.item, 'pending')">{{ $t('remove_approve') }}
                                        </b-button>
                                        <b-button
                                            v-if="row.item.status == 'pending' && checkPermission('scholarship_delete')"
                                            class="text-danger" variant="link" size="xs"
                                            @click="deleteScholarship(row.item)">
                                            {{ $t('delete').toUpper() }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </template>
                    </CommonModal>

                    <div v-for="plan in data.payment_plans[period]" v-bind:key="plan.id" :accesskey="plan.printId = '0'">
                        <h6 class="text-uppercase mb-4">{{ getLocaleText(plan.product, 'name').toUpper() }}</h6>
                        <b-table bordered striped responsive :items="[plan]" :fields="computedPriceFields(plan)"
                            class="mb-4 table-dropdown no-scrollbar border rounded">
                            <template #cell(gross_price)="row">
                                <div v-if="row.item.credits > 0">
                                    {{ row.item.gross_price | formatToCurrency(row.item.currency) }}
                                    ({{ row.item.credit_price | formatToCurrency(row.item.currency) }} x {{ row.item.credits
                                    }})
                                </div>
                                <div v-else>
                                    {{ row.item.gross_price | formatToCurrency(row.item.currency) }}
                                </div>

                            </template>
                            <template #cell(price)="row">
                                <div
                                    :class="row.item.is_cash == false && row.item.payments.length > 0 ? 'badge badge-danger' : ''">
                                    {{ row.item.price | formatToCurrency(row.item.currency) }}
                                </div>
                            </template>
                            <template #cell(cash_price)="row">
                                <div :class="row.item.is_cash && row.item.payments.length > 0 ? 'badge badge-danger' : ''">
                                    {{ row.item.cash_price | formatToCurrency(row.item.currency) }}
                                </div>
                            </template>
                            <template #cell(loa_discount_rate)="row">
                                <span>
                                    <span>{{ row.item.loa_discount_rate }}</span>
                                    <b-button variant="outline-danger ml-2" size="xs" style="display: inline" @click="cancelLoaDiscountRate(row.item.id)"><i class="ri-delete-bin-2-line"></i></b-button>
                                </span>
                            </template>
                            <template #cell(dropdownTable)="row">
                                <div class="d-flex">
                                    <b-button variant="outline-primary" size="xs"
                                        @click="showCustomerPrice(row.item.period_id, row.item.product.id)">
                                        {{ $t('change_price').toUpper() }}
                                    </b-button>

                                    <b-button variant="outline-primary" size="xs" class="ml-1"
                                        @click="editDueDate(row.item)">
                                        {{ $t('edit_due_date').toUpper() }}
                                    </b-button>

                                    <b-button variant="outline-primary" v-if="checkPermission('paymentplan_move')" size="xs"
                                        class="ml-1" @click="movePaymentPlan(row.item)">
                                        {{ $t('move').toUpper() }}
                                    </b-button>

                                    <b-button v-if="plan.payments.length == 0 && checkPermission('payment_delete')"
                                        class="text-danger ml-1" variant="link" size="xs"
                                        @click="deletePaymentPlan(row.item)">
                                        {{ $t('delete').toUpper() }}
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <b-table v-if="plan.payments.length > 0" bordered striped responsive :items="plan.payments"
                            :fields="paymentFields" :tbody-tr-class="paymentRowClass" show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(due_date)="row">
                                <div v-if="row.item.parent_id != null">
                                </div>
                                <div v-else>
                                    {{ row.item.due_date | dateFormat }}
                                </div>
                            </template>
                            <template #cell(amount)="row">
                                <span v-if="row.item.parent_id == null">{{
                                    row.item.amount | formatToCurrency(plan.currency)
                                }}</span>
                            </template>
                            <template #cell(payment_type_name)="row">
                                {{ row.item.payment_type_name }}
                                <span v-if="row.item.payment_type_code == 'ENDORSED'"><br>{{
                                    row.item.account_number
                                }}</span>
                                <span v-if="row.item.is_cancelled" class="badge badge-danger right">{{
                                    toUpperCase('cancelled') }}</span>
                            </template>
                            <template #cell(dropdownTable)="row">
                                <div class="d-flex">
                                    <i class="ri-information-fill ri-2x" v-if="row.item.explanation" style="font-size: 16px"
                                        v-b-tooltip.hover :title="row.item.explanation"></i>
                                    <b-button v-if="!row.item.is_paid && !row.item.is_cancelled" class="mr-1"
                                        variant="outline-primary" size="xs" @click="doPayment(row.item, plan)">{{
                                            $t('do_payment') | toUpperCase }}
                                    </b-button>

                                    <b-button
                                        v-if="row.item.is_paid && row.item.payment_type == 'wire' && row.item.payment_date < '2022-09-01'"
                                        class="mr-1" variant="outline-primary" size="xs" @click="editAmount(row.item)">{{
                                            $t('edit') | toUpperCase }}
                                    </b-button>

                                    <b-button v-if="!row.item.is_paid && row.item.payment_type == 'promissory_note'"
                                        class="mr-1" variant="outline-primary" size="xs" @click="editPayment(row.item)">{{
                                            $t('edit') | toUpperCase }}
                                    </b-button>

                                    <b-button v-if="!row.item.is_paid" class="mr-1" variant="outline-primary" size="xs"
                                        @click="editDelay(row.item)">{{ $t('edit_due_date') | toUpperCase }}
                                    </b-button>

                                    <b-button v-if="!row.item.is_paid && !row.item.is_cancelled" class="mr-1"
                                        variant="outline-danger" size="xs" @click="cancelPayment(row.item.id)">{{
                                            $t('cancel') | toUpperCase }}
                                    </b-button>

                                    <b-button variant="outline-primary"
                                        v-if="checkPermission('payment_move') && !row.item.parent_id" size="xs" class="ml-1"
                                        @click="movePayment(row.item)">
                                        {{ $t('move').toUpper() }}
                                    </b-button>

                                    <b-button v-if="!row.item.ref_id && checkPermission('payment_delete')"
                                        class="text-danger" variant="link" size="xs" @click="deletePayment(row.item.id)">
                                        {{ $t('delete').toUpper() }}
                                    </b-button>
                                </div>
                            </template>
                            <template slot="bottom-row">
                                <td>
                                    <span class="badge badge-danger" style="width: 100%">{{
                                        toUpperCase(plan.is_cash ? 'cash' : 'with_installment')
                                    }}</span>
                                </td>
                                <td></td>
                                <td><b>
                                        {{ plan.payments_sum_amount | formatToCurrency(plan.currency) }}</b></td>
                                <td><b>{{ plan.paid | formatToCurrency(plan.currency) }}</b></td>
                                <td colspan="4"><b>{{ $t('balance') }}:</b> <b
                                        :class="plan.balance < 0 ? 'text-danger' : ''">{{
                                            plan.balance | formatToCurrency(plan.currency)
                                        }}</b>
                                </td>
                            </template>
                        </b-table>
                        <b-table v-if="plan.refunds.length > 0" bordered striped responsive :items="plan.refunds"
                            :fields="refundFields" show-empty class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(amount)="row">
                                {{
                                    (row.item.related_id || row.item.is_offset) ? '' : formatToCurrency(row.item.amount,
                                        'amount', plan)
                                }}
                            </template>
                            <template #cell(cancellation_amount)="row">
                                {{
                                    (row.item.related_id && !row.item.is_offset) ? formatToCurrency(row.item.amount, 'amount',
                                        plan) : ''
                                }}
                            </template>
                            <template #cell(offset_amount)="row">
                                {{ row.item.is_offset ? formatToCurrency(row.item.amount, 'amount', plan) : '' }}
                            </template>
                            <template #cell(payment_type_name)="row">
                                {{ row.item.payment_type_name }}
                                <span v-if="row.item.related_id">({{ $t('payment_id') }}: {{ row.item.related_id }})</span>
                            </template>
                            <template #cell(dropdownTable)="row">
                                <div class="d-flex">

                                    <b-button class="mr-3" variant="outline-primary" size="xs"
                                        @click="editRefund(row.item)">{{ $t('edit') | toUpperCase }}
                                    </b-button>

                                    <b-button class="text-danger" variant="link" size="xs"
                                        @click="deleteRefund(row.item.id)" v-if="checkPermission('refund_delete')">
                                        {{ $t('delete').toUpper() }}
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <div class="d-flex mb-3" v-if="plan.refunds.length > 0">
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('price') }}:</label>
                                <b>{{
                                    plan.price | formatToCurrency(plan.currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                                <b>{{
                                    plan.paid | formatToCurrency(plan.currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('refund') }}:</label>
                                <b>{{
                                    plan.sum_of_refunds | formatToCurrency(plan.currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('cancellation') }}:</label>
                                <b>{{
                                    plan.sum_of_cancellations | formatToCurrency(plan.currency)
                                }}</b>
                            </div>
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('finance_offset') }}:</label>
                                <b>{{
                                    plan.offset_total | formatToCurrency(plan.currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('balance') }}:</label>
                                <b :class="plan.net_balance < 0 ? 'text-danger' : ''">{{
                                    plan.net_balance | formatToCurrency(plan.currency)
                                }}</b>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <b-form-group :label="$t('explanation')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-textarea v-model="plan.explanation" no-resize rows="4"
                                            class="h-40 h-auto-md"></b-form-textarea>
                                        <b-button variant="outline-secondary"
                                            @click="updateExplanation(plan.id, plan.explanation)">
                                            {{ $t('save') }}
                                        </b-button>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-lg-4">
                                <b-form-group :label="$t('upload_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file v-model="file1" :placeholder="$t('select_file')"></b-form-file>
                                        <b-button variant="outline-secondary" class="btn-40"
                                            @click="upload(plan.customer_id, plan.period_id, period)"
                                            v-b-popover.hover.bottom="$t('upload')"><i class="ri-upload-2-line"></i>
                                        </b-button>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-lg-4"
                                v-if="data != null && data.documents != null && data.documents[period] != null && data.documents[period][0].files.length > 0">
                                <b-form-group :label="$t('uploaded_files')">
                                    <div class="mt-1 ">
                                        <div class="mr-5 my-1 d-inline-block"
                                            v-for="item in data.documents[period][0].files">
                                            <a href="javascript:void(0)" @click="download(item.uuid, item.file_name)">{{
                                                item.file_name }}</a>
                                            <a role="button" @click="deleteDocument(item.uuid, period)"
                                                v-if="checkPermission('document_delete')">
                                                <i class="ri-close-line top-plus-3 ml-2 text-muted"></i>
                                            </a>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>

                            <div class="col-12 d-flex align-items-center mb-4">
                                <div class="row">
                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addPayment(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                                    $t('add_payment').toUpper() }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addDiscount(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('discount').toUpper()
                                                }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addBankCredit(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                                    $t('add_bank_credit').toUpper() }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addEducationCredit(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                                    $t('add_education_credit').toUpper()
                                                }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="chooseRefund(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('add_refund').toUpper()
                                                }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto" v-if="plan.net_balance < 0">
                                        <b-button variant="outline-primary mr-3" @click="printRefundForm(plan.id)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                                    $t('refund_form').toUpper() }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addOffset(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('add_offset').toUpper()
                                                }}</span>
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button variant="outline-primary mr-3" @click="addOffsetPayment(plan)"><span
                                                class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                                    $t('add_offset_payment').toUpper() }}</span>
                                        </b-button>
                                    </div>
                                    <div class="col-auto">
                                        <router-link :to="'/payments/collection/' + plan.id" class="btn btn-link">
                                            {{ $t('new_payment_collection').toUpper() }} <i
                                                class="ri-arrow-right-line ml-2"></i>
                                        </router-link>
                                    </div>
                                    <div class="col-auto" v-if="data.customer.group == 'YU'">
                                        <b-button variant="outline-primary mr-3"
                                            @click="createProformaInvoice(plan.id)"><span class="d-flex"><i
                                                    class="ri-add-line mr-2"></i>{{ $t('create_proforma_invoice').toUpper()
                                                    }}</span>
                                        </b-button>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 d-flex align-items-center mb-4">
                                <div class="d-flex custom-file-upload" v-if="getPrints(plan).length > 1">
                                    <multi-selectbox class="width-300" :multiple="false" v-model="plan.printId"
                                        :options="getPrints(plan)"></multi-selectbox>
                                    <b-button variant="outline-secondary" class="btn-40" @click="selectPrint(plan)">
                                        <i class="ri-printer-line"></i>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
            <CommonModal ref="scholarshipModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_scholarship').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="scholarshipForm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                                <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('period')">
                                        <payment-periods-selectbox v-model.lazy="scholarShipForm.period_id">
                                        </payment-periods-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="scholarship_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('scholarship_type') }}</label>
                                        <scholarship-types-selectbox ref="scholarshipType"
                                            v-model="scholarShipForm.scholarship_type_id"></scholarship-types-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                                <ValidationProvider name="discount_rate" ref="scholarshipRate" rules=""
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('discount_rate') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="scholarShipForm.discount_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                                <ValidationProvider name="discount_amount" ref="scholarshipAmount" rules=""
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('discount_amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="scholarShipForm.discount_amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-4">
                                <ValidationProvider name="duration" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('duration') }}</label>
                                        <b-form-select v-model="scholarShipForm.duration">
                                            <option>{{ $t('select') }}</option>
                                            <option value="0">{{ $t('indefinite') }}</option>
                                            <option value="1">1 {{ $t('year') }}</option>
                                            <option value="2">2 {{ $t('year') }}</option>
                                            <option value="3">3 {{ $t('year') }}</option>
                                            <option value="4">4 {{ $t('year') }}</option>
                                            <option value="5">5 {{ $t('year') }}</option>
                                            <option value="6">6 {{ $t('year') }}</option>
                                        </b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-12">
                                <b-form-group>
                                    <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                    <b-form-textarea v-model="scholarShipForm.explanation" no-resize
                                        rows="2"></b-form-textarea>
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-button type="submit" @click="saveScholarship" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="paymentPlanModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment_plan').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="paymentPlanForm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('period')">
                                        <payment-periods-selectbox v-model.lazy="paymentPlanForm.period_id">
                                        </payment-periods-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="product_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('product') }}</label>
                                        <payment-products-selectbox
                                            v-model="paymentPlanForm.product_id"></payment-products-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6"
                                v-if="paymentPlanForm.product_id && [4,5,9,11].includes(paymentPlanForm.product_id)">
                                <ValidationProvider name="credits" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('credit') }}</label>
                                        <b-input type="number" step="1" v-model="paymentPlanForm.credits"></b-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6"
                                v-if="paymentPlanForm.product_id && paymentPlanForm.product_id == 1">
                                <ValidationProvider name="force" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('force_education_price') }}</label>
                                        <b-checkbox v-model="paymentPlanForm.force"></b-checkbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePaymentPlan" variant="primary">{{
                                    $t('create')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="pilotageFeeModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('create_pilotage_fee').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="pilotageFeeForm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="academic_year" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('period')">
                                        <academic-years-selectbox v-model="pilotageFeeForm.academic_year">
                                        </academic-years-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <b-button type="submit" @click="createPilotageFee" variant="primary">{{
                                    $t('create')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="amountModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('edit').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="amountForm">
                        <div class="row" v-if="amountForm">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="amountForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox v-model="amountForm.currency" :validate-error="errors[0]">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" ref="currencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="amountForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="amountForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveAmount" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="paymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="paymentForm">
                        <div class="row" v-if="paymentForm">
                            <div class="col-12" v-if="paymentForm.payments_sum_amount === 0">
                                <ValidationProvider name="is_cash" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cash_or_installed')">
                                        <b-form-radio v-model="paymentForm.is_cash" :value="1">
                                            <span>{{ $t('cash') }}</span>
                                        </b-form-radio>
                                        <b-form-radio v-model="paymentForm.is_cash" :value="0">
                                            <span>{{ $t('with_installment') }}</span>
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="paymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial && paymentForm.show_transfer_next_period">
                                <ValidationProvider name="transfer_next_period" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transfer_next_period') }}</label>
                                        <b-form-checkbox v-model="paymentForm.transfer_next_period"></b-form-checkbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="!allowPartial">
                                <ValidationProvider name="amount" rules="required">
                                    <input type="hidden" v-model="paymentForm.amount" />
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox v-model="paymentForm.currency" :validate-error="errors[0]">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" ref="currencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="paymentForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="paymentForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="paymentForm.bank_id"
                                            :currency="paymentForm.currency" :is_currency_required="true"
                                            v-model="paymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required" ref="paymentFormPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="paymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" ref="paymentFormPaymentDate"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="paymentForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="paymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="offsetPaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="offsetPaymentForm">
                        <div class="row" v-if="offsetPaymentForm">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                v-if="offsetPaymentForm.payments_sum_amount === 0">
                                <ValidationProvider name="is_cash" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cash_or_installed')">
                                        <b-form-radio v-model="offsetPaymentForm.is_cash" :value="1">
                                            <span>{{ $t('cash') }}</span>
                                        </b-form-radio>
                                        <b-form-radio v-model="offsetPaymentForm.is_cash" :value="0">
                                            <span>{{ $t('with_installment') }}</span>
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('period') }}</label>
                                        <payment-periods-selectbox :validate-error="errors[0]"
                                            v-model="offsetPaymentForm.period_id"></payment-periods-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="offsetPaymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <currency-selectbox
                                                      v-model="offsetPaymentForm.currency" :validate-error="errors[0]"></currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="offsetPaymentForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required" ref="offsetPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="offsetPaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" ref="paymentFormPaymentDate"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="offsetPaymentForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="offsetPaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveOffsetPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="findStudentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ toUpperCase('find_student').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('student_number') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                    v-model="findStudentForm.student_number"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('national_id') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                    v-model="findStudentForm.national_id"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('name') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                    v-model="findStudentForm.name"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('surname') }}</label>
                                <b-form-input v-on:keyup.enter="searchStudent"
                                    v-model="findStudentForm.surname"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-button type="button" @click="searchStudent" variant="primary">{{
                                $t('search')
                            }}
                            </b-button>
                        </div>
                    </div>
                    <div class="row"
                        v-if="studentSearchResult && (studentSearchResult.students || studentSearchResult.registrations)">
                        <table class="table table-bordered">
                            <tr>
                                <th>{{ toUpperCase('student_number') }}</th>
                                <th>{{ toUpperCase('name_surname') }}</th>
                                <th>{{ toUpperCase('program_name') }}</th>
                                <th></th>
                                <th class="w-40"></th>
                            </tr>
                            <tr v-for="(student, key) in studentSearchResult.students" :key=key>
                                <td>{{ student.student_number }}</td>
                                <td>{{ student.name }} {{ student.surname }}</td>
                                <td>{{ getLocaleText(student, 'program_name') }} ({{ getLocaleText(student,
                                    'major_type_name') }} - {{ getLocaleText(student, 'student_status_name') }})</td>
                                <td>{{ $t('student') }}</td>
                                <td>
                                    <b-button variant="outline-primary" @click="openStudent(student.student_program_id)">
                                        {{ $t('select_it') }}
                                    </b-button>
                                </td>
                            </tr>
                            <tr v-for="(student, key) in studentSearchResult.registrations" :key=key>
                                <td>{{ student.student_number }}</td>
                                <td>{{ student.name }} {{ student.surname }}</td>
                                <td>{{ getLocaleText(student, 'program_name') }} ({{ student.academic_year }})</td>
                                <td>{{ $t('registration') }}</td>
                                <td>
                                    <b-button variant="outline-primary mr-3"
                                        @click="openRegistration(student.registration_id)">
                                        {{ $t('select_it') }}
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="bankCreditModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_bank_credit').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="bankCreditForm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="bank_credit"
                                            v-model="bankCreditForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="bankCreditForm.date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveBankCredit" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal ref="offsetModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_offset').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="offsetForm">
                        <div class="row" v-if="offsetForm">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="offsetForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="currency_rate" rules="required" ref="offsetCurrencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="offsetForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="transaction_date" rules="required" ref="offsetDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('date') }}</label>
                                        <b-form-input type="date" v-model="offsetForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="offsetForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveOffset" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal ref="offsetDetailModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('offsets_detail') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row" v-if="data && data.offsets">
                        <b-table v-if="data.offsets.length > 0" bordered striped responsive :items="data.offsets"
                            :fields="offsetFields" show-empty class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(period)="row">
                                {{ row.item.amount > 0 ? row.item.period : '' }}
                            </template>
                            <template #cell(amount)="row">
                                <span :class="row.item.amount < 0 ? 'text-danger' : ''">{{
                                    row.item.amount | formatToCurrency(row.item.currency)
                                }}</span>
                            </template>
                            <template #cell(balance)="row">
                                {{ row.item.balance ? formatToCurrency(row.item.balance, 'amount', row.item) : '' }}
                            </template>
                            <template #cell(dropdownTable)="row">
                                <div class="d-flex">

                                    <b-button class="mr-3" variant="outline-primary" size="xs" v-if="row.item.amount > 0"
                                        @click="editOffset(row.item)">{{ $t('edit') | toUpperCase }}
                                    </b-button>

                                    <b-button class="text-danger" variant="link" size="xs"
                                        v-if="row.item.amount > 0 && checkPermission('offset_delete')"
                                        @click="deleteOffset(row.item.id)">
                                        {{ $t('delete') | toUpperCase }}
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <div class="d-flex mb-3" v-if="data.offsets.length > 0 && data.offsets_total">
                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('offset_total') }}:</label>
                                <b>{{
                                    data.offsets_total | formatToCurrency(data.offset_currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                                <b>{{
                                    data.offset_payments_total | formatToCurrency(data.offset_currency)
                                }}</b>
                            </div>

                            <div class="mr-3">
                                <label class="col-form-label mr-1 p-0">{{ $t('balance') }}:</label>
                                <b>{{
                                    data.offset_balance | formatToCurrency(data.offset_currency)
                                }}</b>
                            </div>

                        </div>
                    </div>
                </template>
            </CommonModal>


            <CommonModal ref="promissoryModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('edit_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="promissoryForm">
                        <div class="row" v-if="promissoryForm.id != null">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="promissory_note"
                                            v-model="promissoryForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="promissoryForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('due_date') }}</label>
                                        <b-form-input type="date" v-model="promissoryForm.due_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="currency_rate" rules="required" ref="currencyRatePromissory"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="promissoryForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="promissoryForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePromissory" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="promissoryPaymentModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('promissory_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="promissoryPaymentForm">
                        <div class="row" v-if="promissoryPaymentForm && promissoryPaymentForm.parent_id != null">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="promissoryPaymentForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="promissoryPaymentForm.bank_id"
                                            v-model="promissoryPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_date" rules="required" ref="promissoryPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date"
                                            v-model="promissoryPaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="promissoryPaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePromissoryPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal ref="choosePaymentModal" size="sm">
                <template v-slot:CommonModalContent>
                    <div class="row" v-if="selectedPaymentRow">
                        <div class="col-12" v-if="selectedPaymentRow.payment_type == 'check'">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="endorsePayment">{{
                                $t('endorse') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="wirePayment">
                                {{ $t('wire') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="creditCardPayment">{{ $t('credit_card') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12" v-if="selectedPaymentRow.payment_type == 'check'">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="cashPayment">{{
                                $t('cash_payment') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12" v-if="selectedPaymentRow.payment_type == 'delay'">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="virmanPayment">{{
                                $t('virman') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12" v-if="selectedPaymentRow.payment_type == 'delay'">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="addOffsetPayment(selectedPaymentPlan, selectedPaymentRow)">{{
                                    $t('add_offset_payment') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="addDiscount(selectedPaymentPlan, selectedPaymentRow.id)">{{ $t('discount') |
                                    toUpperCase }}
                            </button>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="endorsePaymentModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('endorse') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="endorsePaymentForm">
                        <div class="row" v-if="endorsePaymentForm && endorsePaymentForm.id != null">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_date" rules="required" ref="endorsePaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="endorsePaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="account_number" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('account_code') }}</label>
                                        <b-form-input v-model="endorsePaymentForm.account_number"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="endorsePaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveEndorsePayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="cashPaymentModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('cash_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="cashPaymentForm">
                        <div class="row" v-if="cashPaymentForm && cashPaymentForm.id != null">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_date" rules="required" ref="cashPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="cashPaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="cash"
                                            v-model="cashPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="cashPaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCashPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="bankCreditPaymentModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('do_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="bankCreditPaymentForm">
                        <div class="row" v-if="bankCreditPaymentForm && bankCreditPaymentForm.id != null">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="payment_date" rules="required" ref="bankCreditPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date"
                                            v-model="bankCreditPaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6"
                                v-if="selectedPaymentRow.payment_type_options && selectedPaymentRow.payment_type_options.allow_partial">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="bankCreditPaymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="bankCreditPaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveBankCreditPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="wireModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="wireForm">
                        <div class="row" v-if="wireForm && wireForm.parent_id != null">
                            <div class="col-12 col-sm-4" v-if="allowPartial">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="wireForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial && wireForm.show_transfer_next_installments">
                                <ValidationProvider name="transfer_next_period" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transfer_next_installments') }}</label>
                                        <b-form-checkbox v-model="wireForm.transfer_next_installments"></b-form-checkbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="!allowPartial">
                                <ValidationProvider name="amount" rules="required">
                                    <input type="hidden" v-model="wireForm.amount" />
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox :validateError="errors[0]" v-model="wireForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-4">
                                <ValidationProvider name="currency_rate" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="wireForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="payment_date" rules="required" ref="wirePaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="wireForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" ref="paymentFormPaymentDate"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="wireForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="wireForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="wireForm.bank_id"
                                            :currency="wireForm.currency" :is_currency_required="true"
                                            v-model="wireForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="wireForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveWire" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="virmanPaymentModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('virman') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="virmanPaymentForm">
                        <div class="row" v-if="virmanPaymentForm.id != null">
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="delay"
                                            v-model="virmanPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveVirmanPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="chooseRefundModal" size="sm">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('refund') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="wireRefund">
                                {{ $t('wire') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="creditCardRefund">{{ $t('credit_card') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="cashRefund">{{
                                $t('cash') | toUpperCase }}
                            </button>
                        </div>
                    </div>
                </template>
            </CommonModal>

            <CommonModal ref="wireRefundModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_refund') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="wireRefundForm">
                        <div class="row" v-if="wireRefundForm">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="wireRefundForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox :validateError="errors[0]" v-model="wireRefundForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="wireRefundForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="refund_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_date') }}</label>
                                        <b-form-input type="date" v-model="wireRefundForm.refund_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="wireRefundForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('refund_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="wireRefundForm.bank_id"
                                            :currency="wireRefundForm.currency" :is_currency_required="true"
                                            v-model="wireRefundForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="reason" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_reason') }}</label>
                                        <parameter-selectbox code="refund_reasons" :validate-error="errors[0]"
                                            v-model="wireRefundForm.reason"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="wireRefundForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveWireRefund" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="creditCardRefundModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_refund') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="creditCardRefundForm">
                        <div class="row" v-if="creditCardRefundForm">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" ref="creditCardRefundBank"
                                            v-model="creditCardRefundForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('refund_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="credit_card"
                                            :is_bank_required="true" :bank_id="creditCardRefundForm.bank_id"
                                            v-model="creditCardRefundForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="creditCardRefundForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox :validateError="errors[0]"
                                            v-model="creditCardRefundForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="creditCardRefundForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="refund_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_date') }}</label>
                                        <b-form-input type="date" v-model="creditCardRefundForm.refund_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="provision" rules="required|length:6" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                        <b-form-input v-model="creditCardRefundForm.related_number" v-mask="'######'"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="terminal" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('terminal')">
                                        <b-form-select :options="terminalOptions"
                                            v-model="creditCardRefundForm.account_number"></b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="card_no" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('card_no')">
                                        <b-form-input autocomplete="false" v-mask="'####-****-****-####'"
                                            v-model="creditCardRefundForm.related_info"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="reason" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_reason') }}</label>
                                        <parameter-selectbox code="refund_reasons" :validate-error="errors[0]"
                                            v-model="creditCardRefundForm.reason"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="creditCardRefundForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCreditCardRefund" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="cashRefundModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_refund') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="cashRefundForm">
                        <div class="row" v-if="cashRefundForm">
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('refund_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="cash"
                                            v-model="cashRefundForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="cashRefundForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox :validateError="errors[0]" v-model="cashRefundForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" ref="cashRefundCurrencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="cashRefundForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="refund_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_date') }}</label>
                                        <b-form-input type="date" v-model="cashRefundForm.refund_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="reason" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('refund_reason') }}</label>
                                        <parameter-selectbox code="refund_reasons" :validate-error="errors[0]"
                                            v-model="cashRefundForm.reason"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="cashRefundForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCashRefund" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="pilotageInvoiceModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('pilotage_invoices') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="pilotageForm">
                        <div class="row" v-if="pilotageForm">
                            <div class="col-12">
                                <ValidationProvider name="invoice1" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('invoice1') }}</label>
                                        <b-form-input v-model="pilotageForm.invoice1"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="invoice2" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('invoice2') }}</label>
                                        <b-form-input v-model="pilotageForm.invoice2"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="invoice3" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('invoice3') }}</label>
                                        <b-form-input v-model="pilotageForm.invoice3"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="invoice4" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('invoice4') }}</label>
                                        <b-form-input v-model="pilotageForm.invoice4"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePilotageInvoices" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="creditPriceModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('credit_price') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="creditPriceForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="academic_year" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('academic_year') }}</label>
                                        <academic-years-selectbox :active-years="true"
                                            v-model="creditPriceForm.academic_year"></academic-years-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="fall_credit" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('fall_credit') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="creditPriceForm.fall_credit"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="spring_credit" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('spring_credit') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="creditPriceForm.spring_credit"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCreditPrice" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="coursePriceModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('course_price') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="coursePriceForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="total_course_count" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('total_course_count') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="coursePriceForm.total_course_count"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="course_count" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('course_count_to_take') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="coursePriceForm.course_count"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCoursePrice" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="scientificPreparationModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('scientific_preparation') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="coursePriceForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="total_course_count" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('total_course_count') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="coursePriceForm.total_course_count"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="course_count" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('course_count_to_take') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="coursePriceForm.course_count"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <b-button type="submit" @click="saveScientificPreparation" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="customerPriceModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('change_price') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="customerPriceForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="price" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('price') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="customerPriceForm.price"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col">
                                        <b-button type="button" @click="saveCustomerPrice" variant="primary">{{
                                            $t('save')
                                        }}
                                        </b-button>
                                    </div>
                                    <div class="col-auto">
                                        <b-button
                                            v-if="customerPriceForm && customerPriceForm.id > 0 && checkPermission('customerprice_delete')"
                                            type="button" @click="deleteCustomerPrice" class="text-danger float-right"
                                            variant="link">{{
                                                $t('delete') | toUpperCase
                                            }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="otherPaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('other_payments') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="otherPaymentForm">
                        <div class="row" v-if="otherPaymentForm && otherPaymentFormRender">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('period') }}</label>
                                        <payment-periods-selectbox :validate-error="errors[0]"
                                            v-model="otherPaymentForm.period_id"></payment-periods-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="product_code" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('price_type') }}</label>
                                        <parameter-selectbox code="other_payment_products" :validate-error="errors[0]"
                                            v-model="otherPaymentForm.product_code"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="process_type" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('process_type')">
                                        <multi-selectbox :multiple="false" :key="'process_type' + Math.random()"
                                            :options="[{ text: $t('debt'), value: 'debt' }, { text: $t('payment'), value: 'payment' }]"
                                            v-model="otherPaymentForm.process_type" :validate-error="errors[0]">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                                <b-form-input type="number" step=".01"
                                                    v-model="otherPaymentForm.amount"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                                <currency-selectbox :validateError="errors[0]"
                                                    v-model="otherPaymentForm.currency">
                                                </currency-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency_rate" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                                <b-form-input type="number" step=".0001"
                                                    v-model="otherPaymentForm.currency_rate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('due_date') }}</label>
                                                <b-form-input type="date"
                                                    v-model="otherPaymentForm.due_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                        v-if="otherPaymentForm.process_type == 'payment'">
                                        <ValidationProvider name="payment_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                                <b-form-input type="date"
                                                    v-model="otherPaymentForm.payment_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                                <b-form-input v-model="otherPaymentForm.explanation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                v-if="otherPaymentForm.process_type && otherPaymentForm.process_type == 'payment'">
                                <ValidationProvider name="payment_method" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_method')">
                                        <multi-selectbox :multiple="false"
                                            :options="[{ text: $t('wire'), value: 'wire' }, { text: $t('credit_card'), value: 'credit_card' }]"
                                            v-model="otherPaymentForm.payment_method" :validate-error="errors[0]">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="otherPaymentForm.payment_method">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="otherPaymentForm.bank_id"
                                            ref="otherPaymentBank">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12"
                                v-if="otherPaymentForm.payment_method && otherPaymentForm.payment_method == 'wire'">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="otherPaymentForm.bank_id"
                                            :currency="otherPaymentForm.currency"
                                            v-model="otherPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                v-if="otherPaymentForm.payment_method && otherPaymentForm.payment_method == 'credit_card'">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="payment_type_id" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('payment_type')">
                                                <payment-types-selectbox :validate-error="errors[0]" type="credit_card"
                                                    :is_bank_required="true" :bank_id="otherPaymentForm.bank_id"
                                                    v-model="otherPaymentForm.payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="provision" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                                <b-form-input type="number"
                                                    v-model="otherPaymentForm.related_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="terminalOptions">
                                        <ValidationProvider name="terminal" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('terminal')">
                                                <b-form-select :options="terminalOptions"
                                                    v-model="otherPaymentForm.account_number"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="card_no" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('card_no')">
                                                <b-form-input autocomplete="false" v-mask="'####-****-****-####'"
                                                    v-model="otherPaymentForm.related_info"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveOtherPayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="otherPaymentsModal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('other_payments') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="showOtherPayment()">
                            <span class="d-flex">
                                <i class="ri-add-line mr-1"></i>
                                {{ $t('new').toUpper() }}
                            </span>
                        </b-button>
                    </div>
                    <div class="row" v-if="otherPayments">
                        <b-table v-if="otherPayments.length > 0" bordered striped foot-clone responsive
                            :items="otherPayments" :fields="otherPaymentFields" show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(paid)="row">
                                <span v-if="row.item.payment_date">{{
                                    row.item.amount | formatToCurrency(row.item.currency)
                                }}</span>
                            </template>
                            <template #cell(dropdownTable)="row">
                                <div class="d-flex">

                                    <b-button class="mr-3" variant="outline-primary" size="xs"
                                        @click="showOtherPayment(row.item)">{{ $t('edit') | toUpperCase }}
                                    </b-button>

                                    <b-button class="mr-3" variant="outline-primary" size="xs"
                                        v-if="row.item.receipt_number" @click="printReceipt(row.item.receipt_number)">{{
                                            $t('print_receipt') | toUpperCase }}
                                    </b-button>

                                    <b-button class="text-danger" variant="link" size="xs"
                                        v-if="checkPermission('otherpayment_delete')"
                                        @click="deleteOtherPayment(row.item.id)">
                                        {{ $t('delete') | toUpperCase }}
                                    </b-button>

                                </div>
                            </template>
                            <template #foot(period)="data">
                                <span></span>
                            </template>
                            <template #foot(product_name)="data">
                                {{ $t('total_debt') }}
                            </template>
                            <template #foot(amount)="data">
                                {{ otherPaymentsSum }}
                            </template>
                            <template #foot(paid)="data">
                                {{ otherPaymentsPaidSum }}
                            </template>
                            <template #foot(due_date)="data">
                                <span></span>
                            </template>
                            <template #foot(explanation)="data">
                                <span>{{ $t('balance') }}</span>
                            </template>
                            <template #foot(payment_date)="data">
                                {{ $t('paid') }}
                            </template>
                            <template #foot(dropdownTable)="data">
                                {{ otherPaymentsSum - otherPaymentsPaidSum }}
                            </template>
                        </b-table>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="educationCreditModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('education_credit') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="educationCreditForm">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01"
                                            v-model="educationCreditForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('start_date') }}</label>
                                        <b-form-input type="date" v-model="educationCreditForm.start_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="number_of_installments" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('number_of_installments') }}</label>
                                        <b-form-input type="number" step="1"
                                            v-model="educationCreditForm.number_of_installments"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <ValidationProvider name="payer" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('guarantee1')">
                                        <b-form-input v-uppercase v-model="educationCreditForm.payer"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <ValidationProvider name="payer_national_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('guarantee1_national_id')">
                                        <b-form-input v-model="educationCreditForm.payer_national_id"
                                            v-mask="'###########'"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <ValidationProvider name="payer_address" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('guarantee1_address')">
                                        <b-form-input v-model="educationCreditForm.payer_address"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <ValidationProvider name="payer_address" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('guarantee1_phone')">
                                        <b-form-input v-model="educationCreditForm.payer_phone"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <b-form-group :label="$t('guarantee2')">
                                    <b-form-input v-uppercase v-model="educationCreditForm.guarantee"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <b-form-group :label="$t('guarantee2_national_id')">
                                    <b-form-input v-model="educationCreditForm.guarantee_national_id"
                                        v-mask="'###########'"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <b-form-group :label="$t('guarantee2_address')">
                                    <b-form-input v-model="educationCreditForm.guarantee_address"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <b-form-group :label="$t('guarantee2_phone')">
                                    <b-form-input v-model="educationCreditForm.guarantee_phone"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveEducationCredit" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="graduatePaymentPlan" size="sm">
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="createGraduatePaymentPlan(1)">
                                {{ $t('whole_price') | toUpperCase }}
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-outline-primary btn-block mb-2" type="button"
                                @click="createGraduatePaymentPlan(2)">
                                {{ $t('first_installment') | toUpperCase }}
                            </button>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="discountModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="discountForm">
                        <div class="row" v-if="discountForm">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="discountForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox v-model="discountForm.currency" :validate-error="errors[0]">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" ref="currencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="discountForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="discount"
                                            v-model="discountForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required" ref="paymentFormPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="discountForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="discountForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="discountForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveDiscount" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="movePaymentPlanModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('move_payment_plan').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12">
                            <multi-selectbox :allow-empty="false" :multiple="false" v-model="movePaymentPlanForm.type"
                                :options="[{ value: 1, text: 'Aynı Numara' }, { value: 2, text: 'Farklı Numara' }]">

                            </multi-selectbox>
                        </div>
                        <div class="col-12 mt-2" v-if="movePaymentPlanForm.type == 2">
                            <b-form-group :label="$t('student_number')">
                                <div class="input-group">
                                    <input type="text" v-model="movePaymentPlanForm.student_number" class="form-control"
                                        aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                            @click="findStudentToMove(movePaymentPlanForm)" type="button"
                                            id="button-addon2">{{ $t('find_student').toUpper() }}</button>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 mt-2" v-if="movePaymentPlanForm.type && studentsToMove.length > 0">
                            <b-form-group :label="$t('program')">
                                <multi-selectbox :allow-empty="false" :multiple="false" v-model="movePaymentPlanForm.id"
                                    :options="studentsToMove">

                                </multi-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12" v-if="movePaymentPlanForm.type && movePaymentPlanForm.id">
                            <b-button type="submit" @click="saveMovePaymentPlan" variant="primary">{{
                                $t('save')
                            }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>

            <CommonModal id="movePaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('move_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12">
                            <multi-selectbox :allow-empty="false" :multiple="false" v-model="movePaymentForm.type"
                                :options="[{ value: 1, text: 'Aynı Numara' }, { value: 2, text: 'Farklı Numara' }]">

                            </multi-selectbox>
                        </div>

                        <div class="col-12 mt-2" v-if="movePaymentForm.type == 2">
                            <b-form-group :label="$t('student_number')">
                                <div class="input-group">
                                    <input type="text" v-model="movePaymentForm.student_number" class="form-control"
                                        aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                            @click="findStudentToMove(movePaymentForm, true)" type="button"
                                            id="button-addon2">{{ $t('find_student').toUpper() }}</button>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 mt-2" v-if="movePaymentForm.type && studentsToMove.length > 0">
                            <b-form-group :label="$t('program')">
                                <multi-selectbox :allow-empty="false" :multiple="false" v-model="movePaymentForm.id"
                                    :options="studentsToMove">

                                </multi-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12 mt-2" v-if="movePaymentForm.type && paymentPlansToMove.length > 0">
                            <b-form-group :label="$t('payment_plan')">
                                <multi-selectbox :allow-empty="false" :multiple="false"
                                    v-model="movePaymentForm.payment_plan_id" :options="paymentPlansToMoveComputed">

                                </multi-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12" v-if="movePaymentForm.type && movePaymentForm.payment_plan_id">
                            <b-button type="submit" @click="saveMovePayment" variant="primary">{{
                                $t('save')
                            }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
            <CommonModal id="proformaInvoiceModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('proforma_invoices').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ProformaInvoiceList v-if="data && data.customer && showProformaInvoice" :customer_id="data.customer.id"
                        :student_program_id="student_program_id" :registration_id="registration_id">

                    </ProformaInvoiceList>
                </template>
            </CommonModal>

            <CommonModal id="vinovModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('vinov_instructions').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <VinovInstructions v-if="data && data.customer && showVinov" :customer_id="data.customer.id"
                        :student_program_id="student_program_id" :registration_id="registration_id"
                        :payment-plans="data.payment_plans">

                    </VinovInstructions>
                </template>
            </CommonModal>

            <CommonModal id="customerNotesModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('notes').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <CustomerNoteList v-if="data && data.customer" :customer_id="data.customer.id" />
                </template>
            </CommonModal>

            <CommonModal id="leaveOfAbsenceModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('leave_of_absence').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="leaveOfAbsenceForm">
                        <div class="row">

                            <div class="col-12">
                                <ValidationProvider name="academic_year" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('academic_year')">
                                        <multi-selectbox v-model="leaveOfAbsenceForm.academic_year" :multiple="false"
                                            :options="leaveOfAbsenceAcademicYearOptions">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="reason" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('reason')">
                                        <parameter-selectbox v-model="leaveOfAbsenceForm.reason"
                                            code="leave_of_absence_reasons">

                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-md-6">
                                <ValidationProvider name="fall" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('fall')">
                                        <b-form-checkbox v-model="leaveOfAbsenceForm.fall">

                                        </b-form-checkbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-md-5">
                                <ValidationProvider name="spring" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('spring')">
                                        <b-form-checkbox v-model="leaveOfAbsenceForm.spring">

                                        </b-form-checkbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveLeaveOfAbsence" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="DocumentModal" size="lg" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('documents').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <div class="mb-2 border rounded p-2 text-center bg-dark text-white">
                        {{ user.student_number }} - {{ user.name }} {{ user.surname }}
                    </div>
                    <b-row>
                        <template v-for="(item, itemKey) in computeDocuments">
                            <b-col cols="6" :key="'document' + itemKey" v-if="checkPermission(item.permission)">
                                <b-dropdown class="mb-2" block split split-variant="outline-dark" variant="dark" right
                                    :text="item.text.toUpper()">
                                    <b-dropdown-item @click="item.callback(lang)" v-for="(lang, langKey) in item.language"
                                        :key="'lang' + langKey">
                                        {{ $t(lang) }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </template>
                    </b-row>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import CommonModal from '@/components/elements/CommonModal';
import CustomerService from '@/services/CustomerService';
import PaymentService from '@/services/PaymentService';
import OtherPaymentService from "@/services/OtherPaymentService";
import PaymentPlanService from '@/services/PaymentPlanService';
import DocumentService from '@/services/DocumentService';
import ScholarshipService from '@/services/ScholarshipService';
import PaymentTypesSelectbox from '@/components/interactive-fields/PaymentTypesSelectbox';
import PaymentPeriodsSelectbox from '@/components/interactive-fields/PaymentPeriodsSelectbox';
import ScholarshipTypesSelectbox from '@/components/interactive-fields/ScholarshipTypesSelectbox';
import BankSelectbox from '@/components/interactive-fields/BankSelectbox';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PaymentProductsSelectbox from '@/components/interactive-fields/PaymentProductsSelectbox';
import RefundService from '@/services/RefundService';
import moment from 'moment';
import StudentService from '@/services/StudentService';
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import CurrencySelectbox from '@/components/interactive-fields/CurrencySelectbox';
import PaymentApprovalExceptionService from '@/services/PaymentApprovalExceptionService';
import PilotageInvoiceService from '@/services/PilotageInvoiceService';
import CustomerPriceService from "@/services/CustomerPriceService";
import CurrencyRateService from "@/services/CurrencyRateService";
import qs from "qs";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ProformaInvoiceList from "@/modules/proformaInvoice/page/List";
import VinovInstructions from "@/modules/vinovInstructions/page/List";
import CustomerNoteList from "@/modules/customerNotes/page/List";
import ProformaInvoice from "@/services/ProformaInvoiceService";
import StudentProgramService from "@/services/StudentProgramService";
import documentData from '@/modules/students/data/Documents';

export default {
    name: 'Payments',
    props: {
        receipt_number: {
            type: String,
            default: '0'
        }
    },
    components: {
        AcademicYearsSelectbox,
        Header,
        HeaderMobile,
        CurrencySelectbox,
        ParameterSelectbox,
        MultiSelectbox,
        PaymentProductsSelectbox,
        AppLayout,
        CommonModal,
        PaymentPeriodsSelectbox,
        ScholarshipTypesSelectbox,
        PaymentTypesSelectbox,
        BankSelectbox,
        ProformaInvoiceList,
        ValidationProvider,
        ValidationObserver,
        CustomerNoteList,
        VinovInstructions
    },
    data() {
        return {
            student_program_id: this.$route.params.student_program_id,
            registration_id: this.$route.params.registration_id,
            data: null,
            scholarShipForm: {},
            paymentPlanForm: {},
            pilotageFeeForm: {},
            promissoryForm: { id: null },
            promissoryPaymentForm: null,
            endorsePaymentForm: null,
            virmanPaymentForm: { id: null },
            cashPaymentForm: null,
            bankCreditPaymentForm: null,
            wireForm: null,
            bankCreditForm: {},
            educationCreditForm: {},
            movePaymentPlanForm: {},
            movePaymentForm: {},
            studentsToMove: [],
            paymentPlansToMove: [],
            offsetForm: null,
            selectedPaymentPlan: null,
            selectedPaymentRow: null,
            allowPartial: true,
            selectedRefundPlan: null,
            paymentForm: {transaction_date:null},
            discountForm: null,
            offsetPaymentForm: null,
            wireRefundForm: null,
            cashRefundForm: null,
            creditCardRefundForm: null,
            findStudentForm: {},
            pilotageForm: null,
            creditPriceForm: {},
            coursePriceForm: {},
            customerPriceForm: {},
            leaveOfAbsenceForm: { academic_year: null, reason: null, fall: false, spring: false },
            leaveOfAbsenceAcademicYearOptions: [],
            otherPaymentForm: null,
            amountForm: null,
            otherPaymentFormRender: true,
            studentSearchResult: null,
            terminalOptions: null,
            disabled: false,
            updating: false,
            size: 'lg',
            selected: null,
            CommonModalMode: false,
            checkSpinner: false,
            paymentControl: false,
            clicked: false,
            checkPayment: false,
            file1: null,
            printId: '0',
            printOptions: null,
            date: new Date(),
            masks: {
                input: 'DD-MM-YYYY'
            },
            showProformaInvoice: false,
            showVinov: false,
            scholarshipFields: [
                {
                    key: 'scholarship_type.name',
                    label: this.toUpperCase('scholarship_type'),
                    sortable: true
                },
                {
                    key: 'discount_rate',
                    label: this.toUpperCase('discount_rate'),
                    sortable: true
                },
                {
                    key: 'discount_amount',
                    label: this.toUpperCase('discount_amount'),
                    sortable: true
                },
                { key: 'dropdownTable', label: '#' }
            ],

            priceFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'gross_price',
                    label: this.toUpperCase('gross_price'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'total_discount_rate',
                    label: this.toUpperCase('discount_rate'),
                    sortable: true
                },
                {
                    key: 'loa_discount_rate',
                    label: this.toUpperCase('loa_discount_rate'),
                    sortable: true
                },
                {
                    key: 'total_discount',
                    label: this.toUpperCase('total_discount'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'price',
                    label: this.toUpperCase('with_installment'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'cash_price',
                    label: this.toUpperCase('cash'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    sortable: true,
                    formatter: this.formatDate
                },
                { key: 'dropdownTable', label: '#', tdClass: 'width-100' }
            ],
            paymentFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount_to_be_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'amount_paid',
                    label: this.toUpperCase('amount_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'payment_type_name', label: this.toUpperCase('payment_type') },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('transaction_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'dropdownTable', label: '#', thClass: 'width-150' }
            ],

            refundFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'refund_date',
                    label: this.toUpperCase('refund_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('refund_amount')
                },
                {
                    key: 'cancellation_amount',
                    label: this.toUpperCase('cancellation_amount')
                },
                {
                    key: 'offset_amount',
                    label: this.toUpperCase('offset_amount')
                },
                { key: 'payment_type_name', label: this.toUpperCase('refund_type') },
                { key: 'dropdownTable', label: '#' }
            ],

            offsetFields: [
                {
                    key: 'period',
                    label: this.toUpperCase('period')
                },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('offset_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance')
                },
                {
                    key: 'explanation',
                    label: this.toUpperCase('explanation')
                },
                { key: 'dropdownTable', label: '#' }
            ],
            otherPayments: null,
            otherPaymentFields: [
                {
                    key: 'period',
                    label: this.toUpperCase('period')
                },
                {
                    key: 'product_name',
                    label: this.toUpperCase('price_type'),
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount'),
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'paid',
                    label: this.toUpperCase('paid'),
                },
                {
                    key: 'explanation',
                    label: this.toUpperCase('explanation')
                },
                { key: 'dropdownTable', label: '#', thClass: 'width-150' }
            ],
            actions: null,
            user: {}
        }
    },
    created() {
        this.loadData();
        if(this.student_program_id>0){
            this.getStudent()
        }
        if (this.receipt_number.toString().length > 0) {
            this.$swal.fire({
                text: this.$t('do_you_want_to_print_receipt'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.printReceipt(this.receipt_number);
                    }
                })
        }
    },
    watch: {
        'scholarShipForm.scholarship_type_id'(newVal) {
            this.scholarshipTypeChanged(newVal);
        },
        'creditCardRefundForm.bank_id': function (selected) {

            if (!selected) {
                return;
            }
            this.terminalOptions = [];
            let item = this.$refs.creditCardRefundBank.getItem(selected);

            if (item.options.terminals) {
                this.terminalOptions = ['', ...item.options.terminals];
            }
        },
        'otherPaymentForm.bank_id': function (selected) {
            this.setOtherPaymentTerminals(selected);
        },
        'otherPaymentForm.currency'(newVal) {
            if (newVal) {
                this.$set(this.otherPaymentForm, 'currency_rate', this.data.currencies[newVal]);
            }
        },
        'paymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'paymentForm.currency'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'wireForm.payment_date'(newVal) {
            this.setCurrencyRate(this.wireForm);
        },
        'wireForm.currency'(newVal) {
            this.setCurrencyRate(this.wireForm);
        },
        'discountForm.payment_date'(newVal) {
            this.setCurrencyRate(this.discountForm);
        },
        'discountForm.currency'(newVal) {
            this.setCurrencyRate(this.discountForm);
        },
        'offsetPaymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.offsetPaymentForm);
        },
        'offsetPaymentForm.currency'(newVal) {
            this.setCurrencyRate(this.offsetPaymentForm);
        },
        'wireRefundForm.refund_date'(newVal) {
            this.setCurrencyRate(this.wireRefundForm);
        },
        'wireRefundForm.currency'(newVal) {
            this.setCurrencyRate(this.wireRefundForm);
        },
        'creditCardRefundForm.refund_date'(newVal) {
            this.setCurrencyRate(this.creditCardRefundForm);
        },
        'creditCardRefundForm.currency'(newVal) {
            this.setCurrencyRate(this.creditCardRefundForm);
        },
        'cashRefundForm.refund_date'(newVal) {
            this.setCurrencyRate(this.cashRefundForm);
        },
        'cashRefundForm.currency'(newVal) {
            this.setCurrencyRate(this.cashRefundForm);
        },
        'movePaymentPlanForm.type'(newVal) {
            this.studentsToMove = [];
            if (newVal == 1) {
                StudentService.studentSearch({ params: { student_number: this.data.customer.number } })
                    .then(response => {
                        var id = 1;
                        response.data.data.students.forEach(item => {
                            if (item.student_program_id == this.student_program_id) {
                                return;
                            }
                            this.studentsToMove.push({ id: item.student_program_id, type: 'student', value: id++, text: this.getLocaleText(item, 'program_name') + ' (Öğrenci) ' + this.getLocaleText(item, 'major_type_name') + ' / ' + this.getLocaleText(item, 'student_status_name') });
                        });
                        response.data.data.registrations.forEach(item => {
                            if (item.registration_id == this.registration_id) {
                                return;
                            }
                            this.studentsToMove.push({ id: item.registration_id, type: 'registration', value: id++, text: this.getLocaleText(item, 'program_name') + ' (Kayıt) ' + item.academic_year });
                        });
                    });
            }
        },
        'movePaymentForm.type'(newVal) {
            this.paymentPlansToMove = [];
            if (newVal == 1) {
                this.studentsToMove = [];
                StudentService.studentSearch({ params: { student_number: this.data.customer.number } })
                    .then(response => {
                        var id = 1;
                        response.data.data.students.forEach(item => {
                            this.studentsToMove.push({ id: item.student_program_id, type: 'student', value: id++, text: this.getLocaleText(item, 'program_name') + ' (Öğrenci) ' + this.getLocaleText(item, 'major_type_name') + ' / ' + this.getLocaleText(item, 'student_status_name') });
                        });
                        response.data.data.registrations.forEach(item => {
                            this.studentsToMove.push({ id: item.registration_id, type: 'registration', value: id++, text: this.getLocaleText(item, 'program_name') + ' (Kayıt) ' + item.academic_year });
                        });
                    });
            }
        },
        'movePaymentForm.id'(newVal) {
            if (!this.movePaymentForm.id) {
                return;
            }
            var id = this.movePaymentForm.id;
            var filtered = this.studentsToMove.filter(function (item) {
                return item.value == id;
            });

            var filter = {};
            if (filtered[0].type == 'student') {
                filter.student_program_id = filtered[0].id;
            }
            else if (filtered[0].type == 'registration') {
                filter.registration_id = filtered[0].id;
            }
            const config = {
                params: {
                    filter: filter,
                    include: ['prices.product', 'prices.period'],
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            this.paymentPlansToMove = [];
            PaymentPlanService.find(config)
                .then(response => {
                    this.paymentPlansToMove = response.data.data;
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        'paymentForm.amount'(newVal){
            this.$set(this.paymentForm, 'show_transfer_next_period', false);
            if(this.selectedPaymentPlan.currency != this.paymentForm.currency){
                return;
            }
            if(newVal-this.selectedPaymentPlan.net_installments_balance>1){
                this.paymentForm.show_transfer_next_period=true;
                this.$set(this.paymentForm, 'show_transfer_next_period', true);
            }
            else {
                this.$set(this.paymentForm, 'transfer_next_period', false);
            }
        },
        'wireForm.amount'(newVal){
            this.$set(this.wireForm, 'show_transfer_next_installments', false);
            if(this.selectedPaymentRow.currency != this.wireForm.currency){
                return;
            }
            if(newVal-this.selectedPaymentRow.balance>0.1){
                this.wireForm.show_transfer_next_installments=true;
                this.$set(this.wireForm, 'show_transfer_next_installments', true);
            }
            else {
                this.$set(this.wireForm, 'transfer_next_installments', false);
            }
        }
    },
    methods: {
        setCurrencyRate(obj) {
            var date = null;
            if (typeof obj.payment_date != 'undefined') {
                date = obj.payment_date;
            }
            if (typeof obj.refund_date != 'undefined') {
                date = obj.refund_date;
            }

            if (!obj || obj.currency == 'TRY' || !date) {
                return;
            }
            CurrencyRateService.getByDate(date, obj.currency)
                .then(response => {
                    if (response.data.data && response.data.data.banknote_selling) {
                        obj.currency_rate = response.data.data.banknote_selling;
                    }
                });
        },
        openStudent(id) {
            if (this.student_program_id == id) {
                return;
            }
            this.student_program_id = id;
            this.registration_id = 0;

            // this.$router.push('/customers/payments/student/'+id);
            this.$refs.findStudentModal.$refs.commonModal.hide();
            this.$router.push('/customers/payments/student/' + id);
            this.loadData();
            this.getStudent()
        },
        openRegistration(id) {
            if (this.registration_id == id) {
                return;
            }
            this.student_program_id = 0;
            this.registration_id = id;
            this.$router.push('/customers/payments/registration/' + id);
            this.$refs.findStudentModal.$refs.commonModal.hide();
            this.loadData();
        },
        loadData() {
            if (this.student_program_id > 0) {
                CustomerService.getStudentPayments(this.student_program_id).then(this.dataCallback).catch(e => {
                    this.showErrors(e);
                });
            } else if (this.registration_id > 0) {
                CustomerService.getRegistrationPayments(this.registration_id).then(this.dataCallback).catch(e => {
                    this.showErrors(e);
                });
            }
        },
        dataCallback(response) {
            this.data = response.data.data;
            if (this.data.customer.error) {
                this.$swal.fire({
                    icon: 'error',
                    text: this.$t(this.data.customer.error),
                })
            }
            if (this.data.customer.leave_of_absences) {
                this.leaveOfAbsenceAcademicYearOptions = this.data.customer.leave_of_absences.map(item => {
                    return { value: item, text: item };
                });
            }
            this.actions = [
                {
                    text: this.$t('proforma_invoices'),
                    permission: 'proformainvoice_index',
                    callback: () => {
                        this.showProformaInvoice = true;
                        this.$bvModal.show('proformaInvoiceModal');
                    },
                    show: () => {
                        return this.data.customer.group == 'YU';
                    }
                },
                {
                    text: this.$t('leave_of_absence'),
                    permission: 'paymentplan_createleaveofabsence',
                    callback: () => {
                        this.$bvModal.show('leaveOfAbsenceModal');
                    },
                    show: () => {
                        return this.student_program_id > 0 &&
                            this.data.customer.leave_of_absences &&
                            this.data.customer.leave_of_absences.length;
                    }
                },
                {
                    text: this.$t('notes'),
                    permission: 'customernote_index',
                    callback: () => {
                        this.$bvModal.show('customerNotesModal');
                    }
                },
                {
                    text: this.$t('vinov_instructions'),
                    permission: 'proformainvoice_index',
                    callback: () => {
                        this.showVinov = true;
                        this.$bvModal.show('vinovModal');
                    },
                    show: () => {
                        return this.data.customer.group == 'TR';
                    }
                },
                {
                    text: this.$t('documents'),
                    permission: 'customernote_index',
                    callback: () => {
                        this.showDocumentModal()
                    }
                },
                {
                    text: this.$t('invoices'),
                    permission: 'invoice_customer',
                    callback: () => {
                        this.$router.push('/customer/invoices/'+this.data.customer.national_id);
                    }
                },
                {
                    text: this.$t('scholarship_status_form'),
                    permission: 'scholarship_scholarshipform',
                    callback: () => {
                        this.printScholarshipForm()
                    }
                },
            ];
        },
        updateExplanation(id, explanation) {
            this.updating = true;
            PaymentPlanService.storeExplanation(id, explanation)
                .then(response => {
                    this.updating = false;
                })
                .catch(e => {
                    this.updating = false;
                });
        },
        upload(customer_id, period_id, period) {
            const formData = new FormData();
            formData.append('customer_id', customer_id);
            formData.append('period_id', period_id);
            formData.append('file', this.file1);

            DocumentService.storeDocument(formData)
                .then(response => {
                    this.data.documents[period] = [response.data.data];
                })
                .catch(error => {
                    this.showErrors(error);
                })
                .finally(() => {
                    this.file1 = null;
                });
        },
        download(uuid, fileName) {
            DocumentService.downloadDocument(uuid)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const data = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = data;
                    link.download = fileName;
                    link.click();
                    setTimeout(function () {
                        window.URL.revokeObjectURL(data);
                    }, 100);
                })
                .catch(error => {
                    this.showErrors(error);
                });
        },
        deleteDocument(uuid, period) {
            DocumentService.deleteDocumentByUuid(uuid)
                .then(response => {
                    if (response.data.success) {
                        this.data.documents[period][0].files;
                        var filtered = this.data.documents[period][0].files.filter(function (value, index) {
                            return value.uuid != uuid;
                        });
                        this.data.documents[period][0].files = filtered;
                    }
                })
        },
        addPaymentPlan() {
            this.$refs.paymentPlanModal.$refs.commonModal.show()
        },
        addPayment(payment_plan) {
            this.selectedPaymentPlan = payment_plan;
            this.allowPartial = true;
            this.$refs.paymentModal.$refs.commonModal.show();
            this.paymentForm = {};
            this.$set(this.paymentForm, 'payment_plan_id', payment_plan.id);
            this.$set(this.paymentForm, 'payments_sum_amount', payment_plan.payments_sum_amount);
            this.$set(this.paymentForm, 'payment_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.paymentForm, 'transaction_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.paymentForm, 'currency', payment_plan.currency);
            this.$set(this.paymentForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            this.$refs.paymentForm.reset();
        },
        addDiscount(payment_plan, parent_id = null) {
            this.$bvModal.show('discountModal');
            this.discountForm = {};
            this.$set(this.discountForm, 'payment_plan_id', payment_plan.id);
            this.$set(this.discountForm, 'payment_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.discountForm, 'currency', payment_plan.currency);
            this.$set(this.discountForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            if (parent_id) {
                this.$set(this.discountForm, 'parent_id', parent_id);
            }
            this.$refs.discountForm.reset();
        },
        addOffsetPayment(payment_plan, payment = null) {
            this.$refs.offsetPaymentModal.$refs.commonModal.show();

            this.offsetPaymentForm = {};
            this.$set(this.offsetPaymentForm, 'payment_plan_id', payment_plan.id);
            this.$set(this.offsetPaymentForm, 'payments_sum_amount', payment_plan.payments_sum_amount);
            this.$set(this.offsetPaymentForm, 'payment_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.offsetPaymentForm, 'transaction_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.offsetPaymentForm, 'currency', payment_plan.currency);
            this.$set(this.offsetPaymentForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            if (payment) {
                this.$set(this.offsetPaymentForm, 'parent_id', payment.id);
                this.$set(this.offsetPaymentForm, 'amount', payment.balance);
            }
            this.$refs.offsetPaymentForm.reset();
        },
        addBankCredit(payment_plan) {
            this.$refs.bankCreditModal.$refs.commonModal.show();
            this.bankCreditForm.payment_plan_id = payment_plan.id;
        },
        addOffset(payment_plan) {
            this.offsetForm = null;
            this.offsetForm = {};
            this.$refs.offsetModal.$refs.commonModal.show();
            this.offsetForm.payment_plan_id = payment_plan.id;
            this.offsetForm.currency = payment_plan.currency;
            this.offsetForm.currency_rate = this.data.currencies[payment_plan.currency];
            this.offsetForm.transaction_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.offsetForm.reset();
        },
        findStudent() {
            this.$refs.findStudentModal.$refs.commonModal.show();
        },
        searchStudent() {
            if (this.findStudentForm.student_number || this.findStudentForm.national_id || (this.findStudentForm.name && this.findStudentForm.surname) || this.findStudentForm.surname) {
                StudentService.studentSearch({ params: this.findStudentForm })
                    .then(response => {
                        this.studentSearchResult = response.data.data;
                    });
            }
        },
        newScholarship() {
            this.scholarShipForm = {};
            this.addScholarship();
        },
        addScholarship() {
            this.$refs.scholarshipForm.reset();
            this.$refs.scholarshipModal.$refs.commonModal.show()
        },
        editScholarship(item) {
            ScholarshipService.get(item.id)
                .then(response => {
                    this.scholarShipForm = response.data.data;
                    this.addScholarship();
                });
        },
        async saveScholarship() {
            const isValid = await this.$refs.scholarshipForm.validate();
            let item = this.$refs.scholarshipType.getItem(this.scholarShipForm.scholarship_type_id);
            if (item.is_rate === true) {
                this.scholarShipForm.discount_amount = 0;
            } else {
                this.scholarShipForm.discount_rate = 0;
            }
            if (isValid) {
                if (this.student_program_id > 0) {
                    this.scholarShipForm.student_program_id = this.student_program_id;
                } else if (this.registration_id > 0) {
                    this.scholarShipForm.registration_id = this.registration_id;
                }
                this.scholarShipForm.customer_id = this.data.customer.id;
                ScholarshipService.store(this.scholarShipForm)
                    .then(response => {
                        this.$refs.scholarshipForm.reset();
                        this.loadData();
                        this.scholarShipForm = {};
                        this.toast(response.data);
                        this.$refs.scholarshipModal.$refs.commonModal.hide()
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.scholarshipForm);
                    });
            }
        },
        approveScholarship(item, status) {
            ScholarshipService.approve(item.id, { status: status })
                .then(response => {
                    this.loadData();
                    this.toast(response.data);
                })
                .catch(error => {
                    this.showErrors(error);
                });
        },
        async deleteScholarship(item) {
            this.deleteModal(() => {
                ScholarshipService.deleteScholarship(item.id)
                    .then(response => {
                        this.loadData();
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        async savePaymentPlan() {
            const isValid = await this.$refs.paymentPlanForm.validate();
            if (isValid) {
                if (this.student_program_id > 0) {
                    this.paymentPlanForm.student_program_id = this.student_program_id;
                } else if (this.registration_id > 0) {
                    this.paymentPlanForm.registration_id = this.registration_id;
                }
                this.paymentPlanForm.customer_id = this.data.customer.id;
                PaymentPlanService.create(this.paymentPlanForm)
                    .then(response => {
                        this.loadData();
                        this.paymentPlanForm = {};
                        this.toast(response.data);
                        this.$refs.paymentPlanModal.$refs.commonModal.hide()
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.paymentPlanForm);
                    });
            }
        },
        async createPilotageFee() {
            const isValid = await this.$refs.pilotageFeeForm.validate();
            if (isValid) {
                if (this.student_program_id > 0) {
                    this.pilotageFeeForm.student_program_id = this.student_program_id;
                }
                this.pilotageFeeForm.customer_id = this.data.customer.id;
                PaymentPlanService.pilotageFee(this.pilotageFeeForm)
                    .then(response => {
                        this.loadData();
                        this.pilotageFeeForm = {};
                        this.toast(response.data);
                        this.$bvModal.hide('pilotageFeeModal');
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.pilotageFeeForm);
                    });
            }
        },
        async deletePaymentPlan(item) {
            this.deleteModal(() => {
                PaymentPlanService.deletePaymentPlan(item.id)
                    .then(response => {
                        this.loadData();
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        async savePayment() {
            const isValid = await this.$refs.paymentForm.validate();
            if (isValid) {

                PaymentService.storeWire(this.paymentForm)
                    .then(response => {
                        this.paymentForm = {};
                        this.$refs.paymentForm.reset();
                        this.toast(response.data);
                        this.$refs.paymentModal.$refs.commonModal.hide()
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.paymentForm);
                    });
            }
        },

        async saveDiscount() {
            const isValid = await this.$refs.discountForm.validate();
            if (isValid) {

                PaymentService.storeDiscount(this.discountForm)
                    .then(response => {
                        this.discountForm = {};
                        this.$refs.discountForm.reset();
                        this.toast(response.data);
                        this.$bvModal.hide('discountModal');
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.discountForm);
                    });
            }
        },

        async saveOffsetPayment() {
            const isValid = await this.$refs.offsetPaymentForm.validate();
            if (isValid) {

                PaymentService.storeOffsetPayment(this.offsetPaymentForm)
                    .then(response => {
                        this.offsetPaymentForm = {};
                        this.$refs.offsetPaymentForm.reset();
                        this.toast(response.data);
                        this.$refs.offsetPaymentModal.$refs.commonModal.hide()
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.offsetPaymentForm);
                    });
            }
        },

        async deletePayment(id) {
            this.deleteModal(() => {
                PaymentService.deletePayment(id)
                    .then(response => {
                        this.loadData();
                        if (response.data.success) {
                            this.toast(response.data);
                        } else {
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        async cancelPayment(id) {
            this.$swal.fire({
                input: 'textarea',
                inputPlaceholder: this.$t('enter_explanation'),
                text: this.$t('are_you_sure_to_cancel'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
                inputValidator: (value) => {
                    if (!value) {
                        return this.$t('enter_explanation')
                    }
                }
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        PaymentService.cancel(id, result.value)
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })

        },
        editPayment(row) {
            this.promissoryForm = {
                id: row.id,
                payment_plan_id: row.payment_plan_id,
                payment_type_id: row.payment_type_id,
                due_date: row.due_date,
                amount: row.amount,
                currency: row.currency,
                currency_rate: row.currency_rate,
                explanation: row.explanation
            };

            if (row.payment_type === 'promissory_note') {
                this.$refs.promissoryModal.$refs.commonModal.show()
            }
        },
        async savePromissory() {
            const isValid = await this.$refs.promissoryForm.validate();
            if (isValid) {

                PaymentService.updatePayment(this.promissoryForm.id, this.promissoryForm)
                    .then(response => {
                        this.$refs.promissoryForm.reset();
                        this.$refs.promissoryModal.$refs.commonModal.hide()
                        this.loadData();
                        this.promissoryForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.promissoryForm);
                    });
            }
        },

        async savePromissoryPayment() {
            const isValid = await this.$refs.promissoryPaymentForm.validate();
            if (isValid) {

                PaymentService.storeWire(this.promissoryPaymentForm)
                    .then(response => {
                        this.$refs.promissoryPaymentForm.reset();
                        this.$refs.promissoryPaymentModal.$refs.commonModal.hide()
                        this.loadData();
                        this.promissoryPaymentForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.promissoryPaymentForm);
                    });
            }
        },

        scholarshipTypeChanged(selected) {
            if (!selected || this.scholarShipForm.id > 0) {
                return;
            }
            let item = this.$refs.scholarshipType.getItem(selected);
            if (item != null) {
                if (item.is_rate === true) {
                    this.$set(this.scholarShipForm, 'discount_rate', 0);
                    if (parseFloat(item.discount_rate) > 0) {
                        this.$set(this.scholarShipForm, 'discount_rate', parseFloat(item.discount_rate));
                    }
                } else {
                    this.$set(this.scholarShipForm, 'discount_rate', 0);
                }
            }
        },
        checkApproval() {
            this.checkSpinner = true
            if (this.student_program_id > 0) {
                CustomerService.approveStudent(this.data.customer.number, this.student_program_id)
                    .then(response => {
                        if (response.data.success === true) {
                            this.paymentControl = true;
                            this.toast(response.data);
                            this.data.customer.payment_status = 'E';
                        } else {
                            this.paymentControl = false;
                            let message = this.$t('api.' + response.data.message);
                            if (typeof response.data.errors != 'undefined') {
                                message += '\n' + response.data.errors[0];
                            }
                            this.$toast.error(message);
                            this.data.customer.payment_status = 'H';
                        }
                    })
                    .catch(e => {
                        this.paymentControl = false;
                        let message = this.$t('api.' + e.data.message);
                        if (typeof e.data.errors != 'undefined') {
                            message += '\n' + e.data.errors[0];
                        }
                        this.$toast.error(message);
                    })
                    .finally(() => {
                        this.checkSpinner = false
                        this.checkPayment = true
                    });
            } else if (this.registration_id > 0) {
                CustomerService.approveRegistration(this.data.customer.number, this.registration_id, this.data.customer.registration_semester.academic_year)
                    .then(response => {
                        if (response.data.success === true) {
                            this.paymentControl = true;
                            this.toast(response.data);
                        } else {
                            this.paymentControl = false;
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(e => {
                        this.paymentControl = false;
                        let message = this.$t('api.' + e.data.message);
                        if (typeof e.data.errors != 'undefined') {
                            message += '\n' + e.data.errors[0];
                        }
                        this.$toast.error(message);
                    })
                    .finally(() => {
                        this.checkSpinner = false
                        this.checkPayment = true
                    });
            }
        },
        givePaymentApprovalException() {
            this.$swal.fire({
                html: '<textarea style="width:100%;height:50px" id="payment_approval_exception_explanation"></textarea><br>' +
                    '<select id="payment_approval_exception_duration">' +
                    '<option value="">Süresiz</option>' +
                    '<option value="3">3 gün</option>' +
                    '<option value="7">1 hafta</option>' +
                    '<option value="15">15 gün</option>' +
                    '<option value="90">3 ay</option>' +
                    '</select>',
                focusConfirm: false,
                icon: null,
                preConfirm: () => {
                    return {
                        'explanation': document.getElementById('payment_approval_exception_explanation').value,
                        'duration': document.getElementById('payment_approval_exception_duration').value,
                    };
                },
                text: this.$t('are_you_sure_to_give_exceptional_payment_approval'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed && result.value) {
                        let formData = {
                            customer_id: this.data.customer.id,
                            student_program_id: this.student_program_id,
                            registration_id: this.registration_id,
                            explanation: result.value.explanation,
                            duration: result.value.duration,
                            status: 'E'
                        };
                        PaymentApprovalExceptionService.store(formData)
                            .then(response => {
                                if (response.data.success) {
                                    this.toast(response.data);
                                    this.data.payment_approval_exception = response.data.data;
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        removePaymentApprovalException() {
            this.$swal.fire({
                inputPlaceholder: this.$t('enter_explanation'),
                text: this.$t('are_you_sure_to_remove_exceptional_payment_approval'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {
                            customer_id: this.data.customer.id,
                            student_program_id: this.student_program_id,
                            registration_id: this.registration_id,
                            status: 'H'
                        };
                        PaymentApprovalExceptionService.store(formData)
                            .then(response => {
                                if (response.data.success) {
                                    this.toast(response.data);
                                    this.data.payment_approval_exception = null;
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        getPrints(plan) {
            let arr = [{ value: '0', text: 'Yazdır' }];
            var i = 1;
            plan.receipt_numbers.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Makbuz: ' + item.receipt_number + ' / ' + item.date,
                    type: 'receipt',
                    id: item.receipt_number
                });
                i++;
            });
            plan.mail_orders.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Mail Order',
                    type: 'mail_order',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.promissory_notes.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Senet',
                    type: 'promissory_note',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.education_credits.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Eğitim Kredisi',
                    type: 'education_credit',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.printOptions = arr;
            return arr;
        },
        selectPrint(plan) {
            if (plan.printId == '0') {
                return;
            }
            let item = this.getOptionItem(plan.printOptions, plan.printId);
            if (item.type == 'receipt') {
                this.printReceipt(item.id);
            } else if (item.type == 'mail_order') {
                this.printMailOrder(item.payment_plan_id, item.id);
            } else if (item.type == 'promissory_note') {
                this.printPromissory(item.payment_plan_id, item.id);
            } else if (item.type == 'education_credit') {
                this.printEducationCredit(item.payment_plan_id, item.id);
            }
        },
        printReceipt(number) {
            if (number == '0') {
                return;
            }
            PaymentService.getReceipt(number)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        printRefundForm(id) {
            RefundService.getRefundForm(id)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        printScholarshipForm() {
            ScholarshipService.scholarshipForm(this.student_program_id)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        printPromissory(paymentPlanId, id) {
            PaymentService.getPromissory(paymentPlanId, id)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        printEducationCredit(paymentPlanId, id) {
            PaymentService.getEducationCredit(paymentPlanId, id)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        printMailOrder(paymentPlanId, id) {
            PaymentService.getMailOrderForm(paymentPlanId, id)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },
        doPayment(row, plan) {
            this.selectedPaymentPlan = plan;
            this.allowPartial = true;
            if (row.payment_type === 'promissory_note' && row.payment_type_code !== 'SE00') {
                this.promissoryPaymentForm = null;
                this.promissoryPaymentForm = {
                    parent_id: row.id,
                    payment_plan_id: row.payment_plan_id,
                    payment_type_id: row.payment_type_id,
                    due_date: row.due_date,
                    amount: row.amount,
                    currency: row.currency,
                    currency_rate: row.currency_rate,
                    explanation: row.explanation
                };

                this.promissoryPaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
                this.$refs.promissoryPaymentModal.$refs.commonModal.show()
                this.$refs.promissoryPaymentForm.reset();
            } else if (['promissory_note', 'check', 'mail_order', 'delay', 'education_credit'].includes(row.payment_type)) {
                this.selectedPaymentRow = row;
                if (['promissory_note', 'check'].includes(row.payment_type)) {
                    this.allowPartial = false;
                }
                this.$refs.choosePaymentModal.$refs.commonModal.show()
            } else if (row.payment_type === 'bank_credit') {
                this.selectedPaymentRow = row;
                this.bankCreditPaymentForm = null;
                this.bankCreditPaymentForm = {
                    id: this.selectedPaymentRow.id,
                    payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                    payment_type_id: this.selectedPaymentRow.payment_type_id,
                    explanation: this.selectedPaymentRow.explanation,
                    payment_date: this.selectedPaymentRow.payment_date
                };
                if (row.payment_type_options && row.payment_type_options.allow_partial === true) {
                    this.bankCreditPaymentForm.amount = this.selectedPaymentRow.balance;
                }
                this.bankCreditPaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
                this.$refs.bankCreditPaymentModal.$refs.commonModal.show();
            } else if (row.payment_type_code === 'PLTJ') {
                this.selectedPaymentRow = row;
                this.$refs.choosePaymentModal.$refs.commonModal.show()
            }
            this.$set(this.selectedPaymentRow, 'currency_rate', this.data.currencies[plan.currency]);
        },
        async saveBankCreditPayment() {
            const isValid = await this.$refs.bankCreditPaymentForm.validate();
            if (isValid) {

                PaymentService.bankCredit(this.bankCreditPaymentForm.id, this.bankCreditPaymentForm)
                    .then(response => {
                        this.$refs.bankCreditPaymentModal.$refs.commonModal.hide()
                        this.loadData();
                        this.bankCreditPaymentForm = { id: null };
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.bankCreditPaymentForm);
                    });
            }
        },
        async saveOffset() {
            const isValid = await this.$refs.offsetForm.validate();
            if (isValid) {

                PaymentService.storeOffset(this.offsetForm)
                    .then(response => {
                        this.$refs.offsetModal.$refs.commonModal.hide()
                        this.loadData();
                        this.offsetForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.offsetForm);
                    });
            }
        },
        endorsePayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.endorsePaymentForm = null;
            this.endorsePaymentForm = {
                id: this.selectedPaymentRow.id,
                payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                payment_type_id: this.selectedPaymentRow.payment_type_id,
                explanation: this.selectedPaymentRow.explanation,
                payment_date: this.selectedPaymentRow.payment_date
            };
            this.endorsePaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.endorsePaymentModal.$refs.commonModal.show();
        },
        virmanPayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.virmanPaymentForm = {
                id: this.selectedPaymentRow.id,
                payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                payment_type_id: this.selectedPaymentRow.payment_type_id
            };
            this.$refs.virmanPaymentModal.$refs.commonModal.show();
        },
        async saveVirmanPayment() {
            const isValid = await this.$refs.virmanPaymentForm.validate();
            if (isValid) {
                this.virmanPaymentForm.transaction_date = moment(new Date()).format('YYYY-MM-DD');
                PaymentService.updatePayment(this.virmanPaymentForm.id, this.virmanPaymentForm)
                    .then(response => {
                        this.$refs.virmanPaymentForm.reset();
                        this.$refs.virmanPaymentModal.$refs.commonModal.hide()
                        this.loadData();
                        this.virmanPaymentForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.virmanPaymentForm);
                    });
            }
        },
        cashPayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.cashPaymentForm = null;
            this.cashPaymentForm = {
                id: this.selectedPaymentRow.id,
                explanation: this.selectedPaymentRow.explanation,
                payment_date: this.selectedPaymentRow.payment_date
            };
            this.cashPaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.cashPaymentModal.$refs.commonModal.show();
        },
        async saveEndorsePayment() {
            const isValid = await this.$refs.endorsePaymentForm.validate();
            if (isValid) {

                PaymentService.endorse(this.endorsePaymentForm.id, this.endorsePaymentForm)
                    .then(response => {
                        this.$refs.endorsePaymentModal.$refs.commonModal.hide()
                        this.loadData();
                        this.endorsePaymentForm = { id: null };
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.endorsePaymentForm);
                    });
            }
        },
        async saveCashPayment() {
            const isValid = await this.$refs.cashPaymentForm.validate();
            if (isValid) {

                PaymentService.cash(this.cashPaymentForm.id, this.cashPaymentForm)
                    .then(response => {
                        this.$refs.cashPaymentModal.$refs.commonModal.hide()
                        this.loadData();
                        this.cashPaymentForm = { id: null };
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.cashPaymentForm);
                    });
            }
        },
        wirePayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.wireForm = null;
            this.wireForm = {
                parent_id: this.selectedPaymentRow.id,
                payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                explanation: this.selectedPaymentRow.explanation,
                payment_date: this.selectedPaymentRow.payment_date,
                amount: this.selectedPaymentRow.balance,
                currency: this.selectedPaymentRow.currency,
                currency_rate: this.data.currencies[this.selectedPaymentRow.currency]
            };

            this.wireForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
            this.wireForm.transaction_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.wireModal.$refs.commonModal.show();
        },
        async saveWire() {
            const isValid = await this.$refs.wireForm.validate();
            if (isValid) {

                PaymentService.storeWire(this.wireForm)
                    .then(response => {
                        this.$refs.wireModal.$refs.commonModal.hide()
                        this.loadData();
                        this.wireForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.wireForm);
                    });
            }
        },
        async saveBankCredit() {
            const isValid = await this.$refs.bankCreditForm.validate();
            if (isValid) {

                PaymentService.storeBankCredit(this.bankCreditForm)
                    .then(response => {
                        this.$refs.bankCreditModal.$refs.commonModal.hide()
                        this.loadData();
                        this.bankCreditForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.bankCreditForm);
                    });
            }
        },
        creditCardPayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.$router.push('/payments/collection/' + this.selectedPaymentRow.payment_plan_id + '/' + this.selectedPaymentRow.id);
        },
        chooseRefund(plan) {
            this.selectedRefundPlan = plan
            this.$refs.chooseRefundModal.$refs.commonModal.show()
        },
        wireRefund() {
            this.wireRefundForm = null;
            this.wireRefundForm = {};
            this.wireRefundForm.currency_rate = this.data.currencies[this.selectedRefundPlan.currency];
            this.wireRefundForm.payment_plan_id = this.selectedRefundPlan.id;
            this.wireRefundForm.currency = this.selectedRefundPlan.currency;
            this.wireRefundForm.refund_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.chooseRefundModal.$refs.commonModal.hide()
            this.$refs.wireRefundModal.$refs.commonModal.show()
        },
        creditCardRefund() {
            this.creditCardRefundForm = null;
            this.creditCardRefundForm = {};
            this.creditCardRefundForm.currency_rate = this.data.currencies[this.selectedRefundPlan.currency];
            this.creditCardRefundForm.payment_plan_id = this.selectedRefundPlan.id;
            this.creditCardRefundForm.currency = 'TRY';
            this.creditCardRefundForm.refund_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.chooseRefundModal.$refs.commonModal.hide()
            this.$refs.creditCardRefundModal.$refs.commonModal.show()
        },
        cashRefund() {
            this.cashRefundForm = null;
            this.cashRefundForm = {};
            this.cashRefundForm.currency_rate = this.data.currencies[this.selectedRefundPlan.currency];
            this.cashRefundForm.payment_plan_id = this.selectedRefundPlan.id;
            this.cashRefundForm.currency = this.selectedRefundPlan.currency;
            this.cashRefundForm.refund_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.chooseRefundModal.$refs.commonModal.hide()
            this.$refs.cashRefundModal.$refs.commonModal.show()
        },
        async saveWireRefund() {
            const isValid = await this.$refs.wireRefundForm.validate();
            if (isValid) {

                RefundService.store(this.wireRefundForm)
                    .then(response => {
                        this.wireRefundForm = {};
                        this.$refs.wireRefundForm.reset();
                        this.toast(response.data);
                        this.$refs.wireRefundModal.$refs.commonModal.hide()
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.wireRefundForm);
                    });
            }
        },
        async saveCashRefund() {
            const isValid = await this.$refs.cashRefundForm.validate();
            if (isValid) {

                RefundService.store(this.cashRefundForm)
                    .then(response => {
                        this.cashRefundForm = {};
                        this.$refs.cashRefundForm.reset();
                        this.toast(response.data);
                        this.$refs.cashRefundModal.$refs.commonModal.hide()
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.cashRefundForm);
                    });
            }
        },
        async saveCreditCardRefund() {
            const isValid = await this.$refs.creditCardRefundForm.validate();
            if (isValid) {

                RefundService.store(this.creditCardRefundForm)
                    .then(response => {
                        this.creditCardRefundForm = {};
                        this.$refs.creditCardRefundForm.reset();
                        this.toast(response.data);
                        this.$refs.creditCardRefundModal.$refs.commonModal.hide()
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.creditCardRefundForm);
                    });
            }
        },
        async deleteRefund(id) {
            this.deleteModal(() => {
                RefundService.del(id)
                    .then(response => {
                        this.loadData();
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        editRefund(row) {

            if (row.payment_type === 'wire') {
                row.bank_id = row.payment_type_bank_id;
                this.wireRefundForm = row;
                this.$refs.wireRefundModal.$refs.commonModal.show()
            }
        },

        editOffset(row) {
            this.offsetForm = row;
            this.$refs.offsetModal.$refs.commonModal.show()
        },

        async deleteOffset(id) {
            this.deleteModal(() => {
                PaymentService.deleteOffset(id)
                    .then(response => {
                        this.loadData();
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        offsetDetail() {
            this.$refs.offsetDetailModal.$refs.commonModal.show()
        },
        paymentRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.parent_id) return 'sub-payments'
        },
        pilotageInvoices() {

            this.pilotageForm = {
                invoice1: null,
                invoice2: null,
                invoice3: null,
                invoice4: null
            };
            PilotageInvoiceService.getByCustomerId(this.data.customer.id).then(response => {
                this.pilotageForm = response.data.data;
            }).finally(() => {
                this.$bvModal.show('pilotageInvoiceModal');
            });
        },
        savePilotageInvoices() {
            this.pilotageForm.customer_id = this.data.customer.id;
            PilotageInvoiceService.store(this.pilotageForm)
                .then(response => {
                    this.toast(response.data);
                }).catch(e => {
                    this.showErrors(e, this.pilotageForm);
                })
        },

        saveCreditPrice() {
            if (!this.creditPriceForm.fall_credit && !this.creditPriceForm.spring_credit) {
                this.$toast.error(this.$t('fill_at_least_one_field'));
            }

            this.creditPriceForm.student_program_id = this.student_program_id;
            PaymentPlanService.credit(this.creditPriceForm).then(response => {
                this.toast(response.data);
                this.$bvModal.hide('creditPriceModal');
                this.loadData();
            })
                .catch(e => {
                    this.showErrors(e, this.$refs.creditPriceForm);
                });
        },
        async saveCoursePrice() {
            const isValid = await this.$refs.coursePriceForm.validate();
            this.coursePriceForm.student_program_id = this.student_program_id;
            if (!isValid) {
                return false;
            }
            PaymentPlanService.course(this.coursePriceForm).then(response => {
                this.toast(response.data);
                this.$bvModal.hide('coursePriceModal');
                this.loadData();
            })
                .catch(e => {
                    this.showErrors(e, this.$refs.coursePriceForm);
                });
        },
        async saveScientificPreparation() {
            const isValid = await this.$refs.coursePriceForm.validate();
            this.coursePriceForm.student_program_id = this.student_program_id;
            this.coursePriceForm.registration_id = this.registration_id;
            this.coursePriceForm.is_scientific_preparation = true;
            if (!isValid) {
                return false;
            }
            PaymentPlanService.course(this.coursePriceForm).then(response => {
                this.toast(response.data);
                this.$bvModal.hide('coursePriceModal');
                this.loadData();
            })
                .catch(e => {
                    this.showErrors(e, this.$refs.coursePriceForm);
                });
        },
        showCustomerPrice(periodId, productId) {
            const config = {
                params: {
                    filter: {
                        customer_id: this.data.customer.id,
                        program_code: this.data.customer.program_code,
                        period_id: periodId,
                        product_id: productId,
                    },
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            CustomerPriceService.getAll(config)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        this.customerPriceForm = response.data.data[0];
                    } else {
                        this.customerPriceForm.id = 0;
                        this.customerPriceForm.period_id = periodId;
                        this.customerPriceForm.product_id = productId;
                        this.customerPriceForm.customer_id = this.data.customer.id;
                        this.customerPriceForm.program_code = this.data.customer.program_code;
                    }
                    this.$bvModal.show('customerPriceModal');
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        saveCustomerPrice() {
            this.customerPriceForm.student_program_id = this.student_program_id;
            this.customerPriceForm.registration_id = this.registration_id;
            if (this.customerPriceForm.id > 0) {
                CustomerPriceService.update(this.customerPriceForm.id, this.customerPriceForm)
                    .then(response => {
                        this.toast(response.data);
                        this.$bvModal.hide('customerPriceModal');
                        this.loadData();
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.customerPriceForm);
                    })
            } else {
                CustomerPriceService.store(this.customerPriceForm)
                    .then(response => {
                        this.toast(response.data);
                        this.$bvModal.hide('customerPriceModal');
                        this.loadData();
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.customerPriceForm);
                    })
            }
        },
        deleteCustomerPrice() {
            if (this.customerPriceForm.id > 0) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            CustomerPriceService.del(this.customerPriceForm.id)
                                .then(response => {
                                    this.toast(response.data);
                                    this.$bvModal.hide('customerPriceModal');
                                    this.loadData();
                                })
                                .catch(e => {
                                    this.showErrors(e, this.$refs.customerPriceForm);
                                })
                        }
                    })

            }
        },
        cancelLoaDiscountRate(id){
            this.$swal.fire({
                text: this.$t('are_you_sure_to_cancel_loa_discount_rate'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        PaymentPlanService.patch(id, {'loa_discount_rate':0})
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        editDelay(row) {
            this.$swal.fire({
                title: this.$t('edit_due_date').toUpper(),
                html: '<input id="delay_due_date" type="date" value="' + row.due_date + '"><br><br><input id="delay_amount" type="number" step=".01" value="' + row.amount + '">',
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        'due_date': document.getElementById('delay_due_date').value,
                        'amount': document.getElementById('delay_amount').value
                    }
                },
                icon: null,
                showCancelButton: true,
                confirmButtonText: this.$t('save'),
                cancelButtonText: this.$t('cancel')
            })
                .then((result) => {
                    if (result.isConfirmed && result.value) {
                        PaymentService.patchPayment(row.id, result.value)
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        editAmount(row) {
            this.amountForm = {
                id: row.id,
                payment_plan_id: row.payment_plan_id,
                payment_type_id: row.payment_type_id,
                amount: row.amount,
                currency: row.currency,
                currency_rate: row.currency_rate,
                due_date: row.due_date,
                explanation: row.explanation
            };
            this.$bvModal.show('amountModal');
        },
        async saveAmount() {
            const isValid = await this.$refs.amountForm.validate();
            if (!isValid) {
                return;
            }
            PaymentService.updatePayment(this.amountForm.id, this.amountForm)
                .then(response => {
                    this.$bvModal.hide('amountModal');
                    this.loadData();
                    this.amountForm = {};
                    this.toast(response.data);
                })
                .catch(error => {
                    this.showErrors(error, this.$refs.amountForm);
                });
        },
        editDueDate(row) {

            let pricesSelect = '<select id="payment_plan_price_id">'
            let $this = this;
            row.all_prices.forEach(function (price) {
                let text = $this.formatToCurrency(price.price, null, price);
                if (price.start_date) {
                    text += ' (' + $this.formatDate(price.start_date) + ' - ' + $this.formatDate(price.end_date);
                }
                pricesSelect += '<option value="' + price.id + '" ' + (price.id == row.price_id ? 'selected' : '') + '>' + text + '</option>';

            });
            pricesSelect += '</select>';

            let cashDiscounts = [{ text: "Taksitli", value: 0 }];
            if (this.data.product_discounts) {
                Object.values(this.data.product_discounts).forEach(function (discount) {
                    if (discount.cash_discount_rate > 0 && discount.product_id == row.product.id && discount.period_id == row.period_id) {
                        cashDiscounts.push({ text: "Peşin %" + discount.cash_discount_rate + " (" + discount.start_date + "-" + discount.end_date, value: discount.cash_discount_rate });
                    }
                });
            }
            let cashDiscountSelect = '<select id="payment_plan_cash_discount_rate">'
            cashDiscounts.forEach(function (cashDiscount) {
                cashDiscountSelect += '<option value="' + cashDiscount.value + '" ' + (cashDiscount.value == row.cash_discount_rate ? 'selected' : '') + '>' + cashDiscount.text + '</option>';
            });
            cashDiscountSelect += '</select>';
            this.$swal.fire({
                title: this.$t('edit_due_date').toUpper(),
                html: '<input id="payment_plan_due_date" type="date" value="' + row.due_date + '"><br><br>' + pricesSelect + '<br><br>' + cashDiscountSelect,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        'due_date': document.getElementById('payment_plan_due_date').value,
                        'cash_discount_rate': document.getElementById('payment_plan_cash_discount_rate').value,
                        'new_price_id': document.getElementById('payment_plan_price_id').value,
                        //'early_discount_rate':document.getElementById('payment_plan_early_discount_rate').value,
                    };
                },
                icon: null,
                showCancelButton: true,
                confirmButtonText: this.$t('save'),
                cancelButtonText: this.$t('cancel')
            })
                .then((result) => {
                    if (result.isConfirmed && result.value) {
                        PaymentPlanService.patch(row.id, result.value)
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        showOtherPayments() {
            const config = {
                params: {
                    filter: {
                        customer_id: this.data.customer.id,
                        student_program_id: this.student_program_id,
                    },
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            OtherPaymentService.getAll(config)
                .then(response => {
                    this.otherPayments = response.data.data;
                    this.$bvModal.show('otherPaymentsModal');
                });
        },
        showOtherPayment(row = {}) {
            this.$bvModal.show('otherPaymentModal');
            this.otherPaymentForm = row;
            this.$set(this.otherPaymentForm, 'process_type', 'debt');
            if (row.payment_type_id > 0) {
                this.$set(this.otherPaymentForm, 'process_type', 'payment');
                this.terminalOptions = null;
                this.terminalOptions = ['', ...row.terminals];
            }
        },
        deleteOtherPayment(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        OtherPaymentService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.showOtherPayments();
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.customerPriceForm);
                            })
                    }
                })
        },
        async saveOtherPayment() {
            const isValid = await this.$refs.otherPaymentForm.validate();
            this.otherPaymentForm.customer_id = this.data.customer.id;
            this.otherPaymentForm.student_program_id = this.student_program_id;
            if (isValid) {
                if (this.otherPaymentForm.id > 0) {
                    OtherPaymentService.update(this.otherPaymentForm.id, this.otherPaymentForm)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('otherPaymentModal');
                            this.showOtherPayments();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.otherPaymentForm);
                        });
                } else {
                    OtherPaymentService.store(this.otherPaymentForm)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('otherPaymentModal');
                            this.showOtherPayments();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.otherPaymentForm);
                        });
                }
            }
        },
        addEducationCredit(payment_plan) {
            this.$bvModal.show('educationCreditModal');
            this.educationCreditForm.payment_plan_id = payment_plan.id;
        },
        async saveEducationCredit() {
            const isValid = await this.$refs.educationCreditForm.validate();
            if (isValid) {

                PaymentService.storeEducationCredit(this.educationCreditForm)
                    .then(response => {
                        this.$bvModal.hide('educationCreditModal');
                        this.loadData();
                        this.educationCreditForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.educationCreditForm);
                    });
            }
        },
        forceRerender(renderComponent) {
            // Remove my-component from the DOM
            renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                renderComponent = true;
            });
        },
        createGraduatePaymentPlan(type) {
            const data = {
                customer_id: this.data.customer.id,
                student_program_id: this.student_program_id,
                registration_id: this.registration_id,
                type: type
            };
            PaymentPlanService.graduate(data)
                .then(response => {
                    this.toast(response.data);
                    this.$bvModal.hide('graduatePaymentPlan');
                    this.loadData();
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        setOtherPaymentTerminals(selected) {

            if (!selected || typeof this.$refs.otherPaymentBank == 'undefined') {
                return;
            }
            this.terminalOptions = [];
            let item = this.$refs.otherPaymentBank.getItem(selected);
            if (item.options.terminals) {
                this.terminalOptions = ['', ...item.options.terminals];
            }
        },
        customerGroupChanged(val) {
            CustomerService.patch(this.data.customer.id, { group: val.value })
                .then(response => {
                    this.toast(response.data);
                }).catch(e => {
                    this.showErrors(e);
                });
        },
        movePaymentPlan(plan) {
            this.movePaymentPlanForm = {};
            this.selectedPaymentPlan = plan;
            this.$bvModal.show('movePaymentPlanModal');
        },
        findStudentToMove(form, allowSame = false) {
            if (!form.student_number) {
                return;
            }

            this.studentsToMove = [];
            StudentService.studentSearch({ params: { student_number: form.student_number } })
                .then(response => {
                    var id = 1;
                    response.data.data.students.forEach(item => {
                        if (item.student_program_id == this.student_program_id && !allowSame) {
                            return;
                        }
                        this.studentsToMove.push({ id: item.student_program_id, type: 'student', value: id++, text: item.name + ' ' + item.surname + ' - ' + this.getLocaleText(item, 'program_name') + ' (Öğrenci) ' + this.getLocaleText(item, 'major_type_name') + ' / ' + this.getLocaleText(item, 'student_status_name') });
                    });
                    response.data.data.registrations.forEach(item => {
                        if (item.registration_id == this.registration_id && !allowSame) {
                            return;
                        }
                        this.studentsToMove.push({ id: item.registration_id, type: 'registration', value: id++, text: item.name + ' ' + item.surname + ' - ' + this.getLocaleText(item, 'program_name') + ' (Kayıt) ' + item.academic_year });
                    });
                });
        },
        saveMovePaymentPlan() {

            if (!this.movePaymentPlanForm.id) {
                return;
            }
            var id = this.movePaymentPlanForm.id;
            var filtered = this.studentsToMove.filter(function (item) {
                return item.value == id;
            });

            var data = {};
            if (filtered[0].type == 'student') {
                data.student_program_id = filtered[0].id;
            }
            else if (filtered[0].type == 'registration') {
                data.registration_id = filtered[0].id;
            }
            PaymentPlanService.move(this.selectedPaymentPlan.id, data)
                .then(response => {
                    this.toast(response.data);
                    this.$bvModal.hide('movePaymentPlanModal');
                    this.loadData();
                })
                .catch(e => {
                    this.showErrors(e);
                });

        },
        saveMovePayment() {
            if (!this.movePaymentForm.payment_plan_id) {
                return;
            }

            PaymentService.move(this.selectedPayment.id, { payment_plan_id: this.movePaymentForm.payment_plan_id })
                .then(response => {
                    this.toast(response.data);
                    this.$bvModal.hide('movePaymentModal');
                    this.loadData();
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        movePayment(payment) {
            this.movePaymentForm = {};
            this.selectedPayment = payment;
            this.$bvModal.show('movePaymentModal');
        },
        async saveLeaveOfAbsence() {
            const isValid = await this.$refs.leaveOfAbsenceForm.validate();
            if (!isValid || (!this.leaveOfAbsenceForm.fall && !this.leaveOfAbsenceForm.spring)) {
                return;
            }

            this.leaveOfAbsenceForm.student_program_id = this.student_program_id;
            const formData = {
                student_program_id: this.student_program_id,
                academic_year: this.leaveOfAbsenceForm.academic_year,
                reason: this.leaveOfAbsenceForm.reason,
                fall: this.leaveOfAbsenceForm.fall ? 1 : 0,
                spring: this.leaveOfAbsenceForm.spring ? 1 : 0
            };
            PaymentPlanService.createLeaveOfAbsence(formData)
                .then(response => {
                    this.toast(response.data);
                    this.$bvModal.hide('leaveOfAbsenceModal');
                    this.loadData();
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        createProformaInvoice(payment_plan_id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_create_a_new_proforma_invoice'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        ProformaInvoice.store({
                            payment_plan_id: payment_plan_id,
                            customer_id: this.data.customer.id,
                            student_program_id: this.student_program_id,
                            registration_id: this.registration_id
                        }).then(response => {
                            this.toast(response.data)
                            this.loadData();
                        }).catch(e => {
                            this.showErrors(e);
                        });
                    }
                })

        },
        computedPriceFields(plan) {
            return this.priceFields.filter((field) => {
                if (field.key == 'loa_discount_rate' && plan.loa_discount_rate == 0) {
                    return false;
                }
                return true;
            });
        },

        showDocumentModal() {
            this.$refs.DocumentModal.$refs.commonModal.show()
        },

        getStudent() {
            return StudentProgramService.get(this.student_program_id)
                .then(response => {
                    this.studentProgram = response.data.data;
                    this.user = {
                        id: this.studentProgram.id,
                        student_number: this.studentProgram.student_number,
                        name: this.studentProgram.name,
                        surname: this.studentProgram.surname,
                        gender: this.studentProgram.gender,
                        nationality_code: this.studentProgram.nationality_code,
                        program_level: this.studentProgram.program_level,
                        registration_type: this.studentProgram.registration_type,
                        registration_type_text: this.studentProgram.registration_type_text,
                        student_status: this.studentProgram.student_status,
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        }
    },
    computed: {
        otherPaymentsSum() {
            let sum = 0;
            this.otherPayments.forEach(element => {
                sum += parseFloat(element.amount);
            });
            return sum;
        },
        otherPaymentsPaidSum() {
            let sum = 0;
            this.otherPayments.forEach(element => {
                if (element.payment_date) {
                    sum += parseFloat(element.amount);
                }
            });
            return sum;
        },
        paymentPlansToMoveComputed() {
            var arr = [];
            this.paymentPlansToMove.forEach(item => {
                arr.push({
                    value: item.id,
                    text: item.prices.period.code + ' - ' + item.prices.product.name + ' - ' + this.$options.filters.formatToCurrency(item.price, item.currency)
                });
            });
            return arr;
        },
        computeDocuments() {
            let documents = [];

            if (this.user && this.user.id && this.user.student_number) {
                documents = documentData(this.user)
            }
            return documents;
        }
    }
}
</script>
<style scoped>
*>>>.sub-payments {
    background-color: #e4f5ee !important;
    font-weight: 500;
}

*>>>button {
    white-space: nowrap;
}

*>>>.table-bordered thead tr th {
    background-color: #005baa !important;
    color: #ffffff;
}

*>>>.btn-xs {
    line-height: 24px !important;
    height: 24px !important;
}
</style>
